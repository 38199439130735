import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicDirectiveDirective} from './dynamic-directive.directive';
import {IframeResizeDirective} from "./iframe-resize.directive";
import {CustomDropdownDirective} from './custom-dropdown.directive';

@NgModule({
  declarations: [DynamicDirectiveDirective, IframeResizeDirective, CustomDropdownDirective],
  imports: [
    CommonModule
  ],
  exports: [
    DynamicDirectiveDirective, IframeResizeDirective, CustomDropdownDirective
  ]
})
export class CalioDirectivesModule {
}
