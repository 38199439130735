import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './static-pages/page-not-found/page-not-found.component';
import {AuthGuardService} from './shared/guard-services/auth-guard.service';
import {ForbiddenPageComponent} from './static-pages/forbidden-page/forbidden-page.component';
import {AuthorizeComponent} from './authorize/authorize.component';
import {LogoutComponent} from './logout/logout.component';
import {LoggerService} from './shared/util-services/logger.service';
import {FeatureProposalGuardService} from './shared/guard-services/feature-proposal-guard.service';
import {SaferpayComponent} from './saferpay/saferpay.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./startsite/startsite.module').then(m => m.StartsiteModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'partner-management',
    loadChildren: () => import('./partner-management-dashboard/partner-management-dashboard.module')
      .then(m => m.PartnerManagementDashboardModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'authorize',
    component: AuthorizeComponent,
  },
  {
    path: 'app',
    loadChildren: () => import('./dashboard/dashboard.module')
      .then(m => m.DashboardModule)
      .catch(err => LoggerService.log('Error while loading dashboard module ', err)),
    data: {
      breadcrumb: 'dashboard',
    },
    canActivate: [
      AuthGuardService,
    ]
  },
  {
    path: 'forbidden',
    component: ForbiddenPageComponent
  },
  {
    path: 'feature-proposals',
    loadChildren: () => import('./feature-proposals/feature-proposals.module').then(m => m.FeatureProposalsModule),
    canActivate: [FeatureProposalGuardService]
  },
  {
    path: 'appointments',
    loadChildren: () => import('./appointments/appointments.module').then(m => m.AppointmentsModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./customers-landing-page/customers-landing-page.module')
      .then(m => m.CustomersLandingPageModule)
  },
  {
    path: 'survey',
    loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule)
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'connect',
    loadChildren: () => import('./connect/connect.module').then(m => m.ConnectModule)
  },
  {
    path: 'saferpay/:status',
    component: SaferpayComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    // relativeLinkResolution: 'legacy',
    // initialNavigation: 'enabled',
    // preload all modules; optionally we could
    // implement a custom preloading strategy for just some
    // of the modules (PRs welcome 😉)
    // preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
