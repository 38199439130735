import {TaxDbModel} from './tax.model';

export class AppointmentServiceDbModel {
  id: number;
  uuid: string;
  name: string;
  duration: string;
  partner_id: number;
  appointment_service_category_id: number;
  tax_id: number;
  price: number;
  description: string;
  is_multi_language: number;
  is_online_payment_enabled: number;
  define_skill: number;
  is_internal: number;
  _translations: {
    [key: string]: TranslationDbModel
  };
  tax: TaxDbModel;
  lead_time?: number;
  followup_time?: number;
  position?: number;
  url_name?: string;
  abbreviation: string;
  is_price_from_activated: number;
}

export class TranslationDbModel {
  category: string;
  description: string;
  name: string;
  locale: string;
  title: string;
  abbreviation?: string;
}
