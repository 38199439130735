import {EventEmitter, Injectable} from '@angular/core';
import {HttpClientService} from '../util-services/http-client.service';
import {Observable} from 'rxjs';
import {MonitoringStats, StartSiteDbModel} from '../db-models/startsite-db.model';
import {WebhookStats} from '../client-models/webhook-stats.model';
import {CalendarAppointmentModel} from '../db-models/calenadar-appointment';
import {BookingEventDao} from '../db-models/event-booking.dao';

@Injectable({
  providedIn: 'root'
})
export class StartSiteService {

  refreshStartSiteDbEvent: EventEmitter<StartSiteDbModel> = new EventEmitter();
  refreshBookingAppointments: EventEmitter<{
    workerIds: number[],
    mode: string,
  }> = new EventEmitter();
  refreshBookingEvents: EventEmitter<{
    workerIds: number[],
    mode: string,
  }> = new EventEmitter();

  constructor(
    private httpClientService: HttpClientService
  ) {
  }

  public getBookingAppointments(body: {
    workers: number[],
    mode: string,
    limit: number,
    offset: number,
  }): Observable<CalendarAppointmentModel[]> {
    return this.httpClientService.post(`appointments/bookings`, body, {});
  }

  public getStartSiteStatsByYear(year: number): Observable<StartSiteDbModel> {
    return this.httpClientService.get(`statistics/${year}`, {});
  }

  public getStartSiteStatsByYearAndCustomer(customerId: number, year: number): Observable<StartSiteDbModel> {
    const body: { year: number, customer_id: number } = {year, customer_id: customerId};
    return this.httpClientService.post(`statistics/customer`, body, {});
  }

  public getWebhookStats(): Observable<WebhookStats> {
    return this.httpClientService.get(`statistics/webhook_requests`, {});
  }

  getMonitoringStats(): Observable<MonitoringStats> {
    return this.httpClientService.get('statistics/monitoring', {});
  }

  public getBookingEvents(body: {
    workers: number[],
    mode: string,
    limit: number,
    offset: number,
  }): Observable<BookingEventDao[]> {
    return this.httpClientService.post(`customers_slots/bookings`, body, {});
  }
}
