import {EventEmitter, Injectable} from '@angular/core';
import {HttpClientService} from '../util-services/http-client.service';
import {Observable, of} from 'rxjs';
import {AuthRole, ServiceWorkerDbModel, WorkerDbModel} from '../db-models/worker-db.model';
import {EmployeeFormModel, ServiceWorkerModel} from '../client-models/employee-form.model';
import {LocalStorageService} from '../util-services/local-storage.service';
import {CryptoService} from '../util-services/crypto.service';
import {LOCAL_STORAGE_CONSTANT} from '../constants/localstorage.constant';
import {switchMap} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {HelperService} from '../util-services/helper.service';
import {LoggerService} from '../util-services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class WorkerService {

  onWorkerSelectionChanged: EventEmitter<WorkerDbModel> = new EventEmitter();
  onWorkerIdsSelectionChanged: EventEmitter<number[]> = new EventEmitter();

  constructor(
    private httpClientService: HttpClientService,
    private localstorageService: LocalStorageService,
    private cryptoService: CryptoService,
    private helperService: HelperService,
  ) {
  }

  importWorkersExcel(file: File, partnerId: number): Observable<WorkerDbModel[]> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('partner_id', partnerId + '');
    return this.httpClientService.post('workers/import', formData, {});
  }

  public getWorkersByPagination(limit: number, offset: number): Observable<WorkerDbModel[]> {
    const params = new HttpParams()
      .set('limit', limit + '')
      .set('offset', offset + '');
    return this.httpClientService.get('workers', {params: params}).pipe(
      (switchMap((dbWorkers: WorkerDbModel[]) => {
        for (const worker of dbWorkers) {
          if (worker.services_workers && worker.services_workers.length > 0) {
            worker.services_workers = worker.services_workers.filter((item: ServiceWorkerDbModel) => {
              return item.appointment_service !== null;
            });
          }
        }
        return of(dbWorkers);
      }))
    );
  }

  public searchWorkers(query: string, storeId: number, limit: number, offset: number): Observable<WorkerDbModel[]> {
    const params = new HttpParams()
      .set('limit', limit + '')
      .set('offset', offset + '');
    if (storeId && query) {
      const body: { query: string, store_id: number } = {query: query, store_id: storeId};
      return this.httpClientService.post(`workers/search`, body, {params: params});
    } else if (storeId) {
      const body: { store_id: number } = {store_id: storeId};
      return this.httpClientService.post(`workers/search`, body, {params: params});
    } else {
      const body: { query: string } = {query: query};
      return this.httpClientService.post(`workers/search`, body, {params: params});
    }
  }

  public getWorkers(): Observable<WorkerDbModel[]> {
    const workers: WorkerDbModel[] = this.getWorkersFromLocalStorage();
    if (workers) {
      for (const worker of workers) {
        if (worker.services_workers && worker.services_workers.length > 0) {
          worker.services_workers = worker.services_workers.filter((item: ServiceWorkerDbModel) => {
            return item.appointment_service !== null;
          });
        }
      }
      return of(workers);
    }

    return this.httpClientService.get('workers', {}).pipe(
      (switchMap((dbWorkers: WorkerDbModel[]) => {
        for (const worker of dbWorkers) {
          if (worker.services_workers && worker.services_workers.length > 0) {
            worker.services_workers = worker.services_workers.filter((item: ServiceWorkerDbModel) => {
              return item.appointment_service !== null;
            });
          }
          if (!worker.calendar_color) {
            worker.calendar_color = this.helperService.getRandomColor();
          }
        }
        return of(dbWorkers);
      }))
    );
  }

  getLoggedInWorkerFromLocalStorage(): WorkerDbModel {
    const encryptedWorker: string = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.LOGGED_IN_WORKER);
    if (encryptedWorker) {
      const decryptedWorkers: string = this.cryptoService.decryptValue(encryptedWorker);
      const worker: WorkerDbModel = JSON.parse(decryptedWorkers);
      return worker;
    } else {
      // logout user
      // this.authService.logout();
      return null;
    }
  }

  setLoggedInUserInLocalStorage(auth0UserId: string): Observable<WorkerDbModel> {
    const body: {
      auth0_user_id: {
        value: string,
        operator: string
      }
    } = {
      auth0_user_id: {
        value: auth0UserId,
        operator: '='
      }
    };

    return this.filterWorkers(body).pipe(
      (switchMap((workers: WorkerDbModel[]) => {
        LoggerService.log('Inside filter workers: ', workers);
        if (workers && workers.length === 1) {
          const encryptedString = this.cryptoService.encryptValue(
            JSON.stringify(workers[0])
          );
          this.localstorageService.set(LOCAL_STORAGE_CONSTANT.LOGGED_IN_WORKER, encryptedString);
          return of(workers[0]);
        }
        if (workers && workers.length > 0) {
          const tempList: string[] = auth0UserId.split('|');
          if (tempList && tempList.length > 0) {
            const workerId: number = Number(tempList[1]);
            const foundedWorker: WorkerDbModel = workers.find((item: WorkerDbModel) => {
              return item.id === workerId;
            });
            const encryptedString = this.cryptoService.encryptValue(
              JSON.stringify(foundedWorker)
            );
            this.localstorageService.set(LOCAL_STORAGE_CONSTANT.LOGGED_IN_WORKER, encryptedString);
            return of(foundedWorker);
          }
        }

        return of(null);
      }))
    );
  }

  public filterWorkers(body: {
    auth0_user_id?: {
      value: string,
      operator: string
    },
    is_lead_generator?: {
      value: string,
      operator: string
    }
  }) {
    return this.httpClientService.post(`workers/filter`, body, {}).pipe(
      (switchMap((workers: WorkerDbModel[]) => {
        if (workers) {
          return of(workers);
        } else {
          of([]);
        }
      }))
    );
  }

  public getWorkerById(uuid: string): Observable<WorkerDbModel> {
    return this.httpClientService.get(`workers/${uuid}`, {}).pipe(
      (switchMap((result: WorkerDbModel) => {
        if (result && result && result.services_workers && result.services_workers.length > 0) {
          result.services_workers = result.services_workers.filter((item: ServiceWorkerDbModel) => {
            return item.appointment_service !== null;
          });
        }
        return of(result);
      }))
    );
  }

  public saveWorker(employeeFormModel: EmployeeFormModel): Observable<WorkerDbModel> {
    this.clearWorkersFromLocalStorage();
    if (employeeFormModel.id) {
      return this.editWorker(employeeFormModel);
    } else {
      return this.createWorker(employeeFormModel);
    }
  }

  public createWorker(employeeFormModel: EmployeeFormModel): Observable<WorkerDbModel> {
    return this.httpClientService.post('workers', employeeFormModel, {});
  }

  public editWorker(employeeFormModel: EmployeeFormModel): Observable<WorkerDbModel> {
    if (employeeFormModel?.uuid) {
      return this.httpClientService.put(`workers/${employeeFormModel.uuid}`, employeeFormModel, {});
    } else {
      console.warn('Failed to edit worker as uuid is missing', employeeFormModel);
      return null;
    }
  }

  public setLanguage(workerId: number, language: string): Observable<{ worker: WorkerDbModel }> {
    const body = {
      worker_id: workerId,
      lang_identifier: language
    };
    return this.httpClientService.post(`workers/language`, body, {});
  }

  public saveServiceWorkers(serviceWorkerModel: ServiceWorkerModel): Observable<{ skills: ServiceWorkerDbModel[] }> {
    return this.httpClientService.post(`services_workers`, serviceWorkerModel, {});
  }

  public deleteWorker(uuid: string): Observable<{ success: boolean }> {
    this.clearWorkersFromLocalStorage();
    return this.httpClientService.delete(`workers/${uuid}`, {});
  }

  public getLimitedWorkersList(): Observable<WorkerDbModel[]> {
    const workers: WorkerDbModel[] = this.getShortWorkersFromLocalStorage();
    if (workers) {
      return of(workers);
    }

    return this.httpClientService.get(`workers/short`, {}).pipe(
      (switchMap((dbWorkers: WorkerDbModel[]) => {
        for (const worker of dbWorkers) {
          if (!worker.calendar_color) {
            worker.calendar_color = this.helperService.getRandomColor();
          }
        }
        this.setShortWorkersInLocalStorage(dbWorkers);
        return of(dbWorkers);
      }))
    );
  }

  public updateSingleWorkerInLocalStorage(worker: WorkerDbModel) {
    const workers: WorkerDbModel[] = this.getWorkersFromLocalStorage();
    if (workers) {
      const foundIndex: number = workers.findIndex(x => x.id === worker.id);
      workers[foundIndex] = worker;
      this.setWorkersInLocalStorage(workers);
    }
  }

  public setShortWorkersInLocalStorage(workers: WorkerDbModel[]): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(workers)
    );
    this.localstorageService.set(LOCAL_STORAGE_CONSTANT.SHORT_WORKER_LIST_KEY, encryptedString);
  }

  public getShortWorkersFromLocalStorage(): WorkerDbModel[] {
    const encryptedWorkers: string = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.SHORT_WORKER_LIST_KEY);
    if (encryptedWorkers) {
      const decryptedWorkers: string = this.cryptoService.decryptValue(encryptedWorkers);
      const workers: WorkerDbModel[] = JSON.parse(decryptedWorkers);
      return workers;
    } else {
      return null;
    }
  }

  public setWorkersInLocalStorage(workers: WorkerDbModel[]): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(workers)
    );
    this.localstorageService.set(LOCAL_STORAGE_CONSTANT.WORKERS_KEY, encryptedString);
  }

  public getWorkersFromLocalStorage(): WorkerDbModel[] {
    const encryptedWorkers: string = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.WORKERS_KEY);
    if (encryptedWorkers) {
      const decryptedWorkers: string = this.cryptoService.decryptValue(encryptedWorkers);
      const workers: WorkerDbModel[] = JSON.parse(decryptedWorkers);
      return workers;
    } else {
      return null;
    }
  }

  public createClientWorkerFormModel(workerDbModel: WorkerDbModel): EmployeeFormModel {
    const employeeFormModel: EmployeeFormModel = new EmployeeFormModel();
    employeeFormModel.id = workerDbModel.id;
    employeeFormModel.prename = workerDbModel.prename;
    employeeFormModel.lastname = workerDbModel.lastname;
    employeeFormModel.username = workerDbModel.username;
    employeeFormModel.booking_label = workerDbModel.booking_label;
    employeeFormModel.description = workerDbModel.description;
    employeeFormModel.email = workerDbModel.email;
    employeeFormModel.job_title = workerDbModel.job_title;
    employeeFormModel.conference_url = workerDbModel.conference_url;
    employeeFormModel.store_id = workerDbModel.store_id;
    employeeFormModel.bookable = workerDbModel.bookable === 1 ? true : false;
    employeeFormModel.show_in_pro_calendar = workerDbModel.show_in_pro_calendar === 1 ? true : false;
    employeeFormModel.calendar_color = workerDbModel.calendar_color;
    return employeeFormModel;
  }

  public clearWorkersFromLocalStorage() {
    this.localstorageService.remove(LOCAL_STORAGE_CONSTANT.WORKERS_KEY);
    this.localstorageService.remove(LOCAL_STORAGE_CONSTANT.SHORT_WORKER_LIST_KEY);
  }

  public uploadAvatar(file: File, relativePath: string, workerId: number): Observable<{ worker: WorkerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('avatar', file, relativePath);
    return this.httpClientService.post(`workers/avatar/${workerId}`, formData, {});
  }

  public deleteWorkerSkill(id: number): Observable<{ success: boolean }> {
    return this.httpClientService.delete(`services_workers/${id}`, {});
  }

  public updateWorker(worker: WorkerDbModel): Observable<WorkerDbModel> {
    return this.httpClientService.put(`workers/${worker.uuid}`, worker, {});
  }

  public resetWorkerPassword(email: string): Observable<{ success: boolean }> {
    const body: { [key: string]: string } = {email};
    return this.httpClientService.post(`workers/reset`, body, {});
  }

  public changeWorkerPassword(body: {
    worker_id: number,
    old_password: string,
    password: string,
    password_repeat: string
  }): Observable<{ success: boolean }> {
    return this.httpClientService.post(`workers/password`, body, {});
  }

  public createNewWorkerPassword(body: {
    worker_id: number,
    token: string,
    password: string,
    password_repeat: string
  }): Observable<{ success: boolean }> {
    return this.httpClientService.post(`workers/new_password`, body, {});
  }

  public loginAsResource(support_partner_id: number): Observable<{ success: boolean }> {
    const body: { partner_id: number } = {partner_id: support_partner_id};
    return this.httpClientService.post(`workers/partnerAuth`, body, {});
  }

  setAnonymousUserInLocalStorage(user: { name: string, email: string }) {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(user)
    );
    this.localstorageService.set(LOCAL_STORAGE_CONSTANT.ANONYMOUS_USER_CREDENTIALS, encryptedString);
  }

  public getAnonymousUserFromLocalStorage(): { name: string, email: string } {
    const encrypted: string = this.localstorageService.get(LOCAL_STORAGE_CONSTANT.ANONYMOUS_USER_CREDENTIALS);
    if (encrypted) {
      const decryptedWorkers: string = this.cryptoService.decryptValue(encrypted);
      const user: { name: string, email: string } = JSON.parse(decryptedWorkers);
      return user;
    } else {
      return null;
    }
  }

  getWorkerAvatarFromLocalStorage(): string {
    return this.localstorageService.get(LOCAL_STORAGE_CONSTANT.AVATAR_KEY);
  }

  public getRoles(): Observable<AuthRole[]> {
    return this.httpClientService.get(`roles`, {});
  }

  public loginAsReseller(partnerId: number): Observable<{ success: boolean }> {
    const body: { partner_id: number } = {partner_id: partnerId};
    return this.httpClientService.post(`workers/reseller_auth`, body, {});
  }

  public resetResellerAdmin(): Observable<{ success: boolean }> {
    return this.httpClientService.post(`workers/reseller_logout`, {}, {});
  }
}
