import {Component, OnInit} from '@angular/core';
import {Auth0Service} from "../shared/feature-services/auth-0.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private auth0Service: Auth0Service,
  ) {
    this.auth0Service.logout();
  }

  ngOnInit(): void {
  }

}
