import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImportDynamicComponentService {
  constructor() {}

  public importDealFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/deals-dashboard/deals-form/deals-form.component'
      ).then((m) => m.DealsFormComponent);
  }

  public importCalioSettingFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../startsite/calio-settings-form/calio-settings-form.component'
      ).then((m) => m.CalioSettingsFormComponent);
  }

  public importCustomerNotesFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/customer-notes-form/customer-notes-form.component'
      ).then((m) => m.CustomerNotesFormComponent);
  }

  public importCreateSettingComponent(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/crm-dashboard/common/manage-partners-settings/create-form/create-form.component'
      ).then((m) => m.CreateFormComponent);
  }

  public importQuestionFieldFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/question-fields/question-fields-form/question-fields-form.component'
      ).then((m) => m.QuestionFieldsFormComponent);
  }

  public importCompanySettingTranslationFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/company-setting-translation/company-setting-translation-form/company-setting-translation-form.component'
      ).then((m) => m.CompanySettingTranslationFormComponent);
  }

  public importStripeCardDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/stripe-card-dialog/stripe-card-dialog.component'
      ).then((m) => m.StripeCardDialogComponent);
  }

  public importResellerInviteComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/dashboard-common/reseller-feature/reseller-invite-dialog/reseller-invite-dialog.component'
      ).then((m) => m.ResellerInviteDialogComponent);
  }

  public importServiceCategoryComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/appointment-forms/create-service-category/create-service-category.component'
      ).then((m) => m.CreateServiceCategoryComponent);
  }

  public importAppointmentServiceComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/appointment-forms/appointment-services-form/appointment-services-form.component'
      ).then((m) => m.AppointmentServicesFormComponent);
  }

  public importAppointmentServiceQuestionComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/appointment-forms/appointment-services-questions/appointment-services-questions.component'
      ).then((m) => m.AppointmentServicesQuestionsComponent);
  }

  public importEmployeeFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/employee-form/employee-form-dialog/employee-form-dialog.component'
      ).then((m) => m.EmployeeFormDialogComponent);
  }

  public importStoreFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/store-form/stores-form/stores-form.component'
      ).then((m) => m.StoresFormComponent);
  }

  public importSmartWidgetStepFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/smart-widget-step-form/smart-widget-step-form.component'
      ).then((m) => m.SmartWidgetStepFormComponent);
  }

  public importWidgetAppointmentGroupFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/widget-appointment-group/widget-appointment-group-form/widget-appointment-group-form.component'
      ).then((m) => m.WidgetAppointmentGroupFormComponent);
  }

  public importCalendarSetupDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/calendar-setup/calendar-setup.component'
      ).then((m) => m.CalendarSetupComponent);
  }

  public importAvailabilitiesFormSwitcherComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/availability-forms/availabilities-form-switcher/availabilities-form-switcher.component'
      ).then((m) => m.AvailabilitiesFormSwitcherComponent);
  }

  public importAbsensesFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/absences-forms/absenses-form/absenses-form.component'
      ).then((m) => m.AbsensesFormComponent);
  }

  public importTemplateFormDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/template-form-dialog/templates-form-dialog/templates-form-dialog.component'
      ).then((m) => m.TemplatesFormDialogComponent);
  }

  public importCustomerTypesFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/settings/pages/customer-types-settings/customer-types-form/customer-types-form.component'
      ).then((m) => m.CustomerTypesFormComponent);
  }

  public importCreateCalendarTypeComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/settings/pages/calendar-types-settings/create-calendar-type/create-calendar-type.component'
      ).then((m) => m.CreateCalendarTypeComponent);
  }

  public importPartnerSettingModalComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/partner-setting-modal/partner-setting-modal.component'
      ).then((m) => m.PartnerSettingModalComponent);
  }

  public importJsonViewerComp(): () => Promise<any> {
    return () =>
      import('../../calio-dynamic-lib/json-viewer/json-viewer.component').then(
        (m) => m.JsonViewerComponent
      );
  }

  public importJsonDiffViewerComp(): () => Promise<any> {
    return () =>
      import('../../calio-dynamic-lib/json-diff-viewer/json-diff-viewer.component').then(
        (m) => m.JsonDiffViewerComponent
      );
  }

  public importTaxesFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/settings/pages/taxes-settings/taxes-form/taxes-form.component'
      ).then((m) => m.TaxesFormComponent);
  }

  public importConnectStripeStepperComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/payment-dashboard/connect-stripe-stepper/connect-stripe-stepper.component'
      ).then((m) => m.ConnectStripeStepperComponent);
  }

  public importCalensoWidgetModalComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/calenso-widget-modal/calenso-widget-modal.component'
      ).then((m) => m.CalensoWidgetModalComponent);
  }

  public importFeatureProposalDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../feature-proposals/feature-proposal-dialog/feature-proposal-dialog.component'
      ).then((m) => m.FeatureProposalDialogComponent);
  }

  public importFeatureProposalAnonymousUserFormComp(): () => Promise<any> {
    return () =>
      import(
        '../../feature-proposals/feature-proposal-anonymous-user-form/feature-proposal-anonymous-user-form.component'
      ).then((m) => m.FeatureProposalAnonymousUserFormComponent);
  }

  public importRebookAppointmentComp(): () => Promise<any> {
    return () =>
      import(
        '../../appointments/rebook-appointment-dialog/rebook-appointment-dialog.component'
      ).then((m) => m.RebookAppointmentDialogComponent);
  }

  public importConfirmationModalComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/confirmation-modal/confirmation-modal.component'
      ).then((m) => m.ConfirmationModalComponent);
  }

  public importDeleteEventConfirmationModalComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/delete-event-confirmation-modal/delete-event-confirmation-modal.component'
      ).then((m) => m.DeleteEventConfirmationModalComponent);
  }

  public importEventSlotParticipantComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/events-dashboard/common/event-slot-participant/event-slot-participant.component'
      ).then((m) => m.EventSlotParticipantComponent);
  }

  public importEventSlotEditComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/events-dashboard/common/event-slot-edit-dialog/event-slot-edit-dialog.component'
      ).then((m) => m.EventSlotEditDialogComponent);
  }

  public importSmartActionTriggerComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/smart-actions/smart-action-card/smart-action-trigger-dialog/smart-action-trigger-dialog.component'
      ).then((m) => m.SmartActionTriggerDialogComponent);
  }

  public importImageUploadDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/image-upload-dialog/image-upload-dialog.component'
      ).then((m) => m.ImageUploadDialogComponent);
  }

  public importDeleteAccountConfirmationDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/delete-account-confirmation-modal/delete-account-confirmation-modal.component'
      ).then((m) => m.DeleteAccountConfirmationModalComponent);
  }

  public importCustomerBookingDeleteComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/crm-dashboard/common/customer-booking-delete-confirmation-dialog/customer-booking-delete-confirmation-dialog.component'
      ).then((m) => m.CustomerBookingDeleteConfirmationDialogComponent);
  }

  public importEventCopyCodeComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/events-dashboard/common/event-copy-code-dialog/event-copy-code-dialog.component'
      ).then((m) => m.EventCopyCodeDialogComponent);
  }

  public importEventAddSlotBottomSheetComp(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/events-dashboard/common/event-add-slot-bottomsheet/event-add-slot-bottomsheet.component'
      ).then((m) => m.EventAddSlotBottomsheetComponent);
  }

  public importBryntumEventEditorComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/bryntum-event-forms/bryntum-event-editor/bryntum-event-editor.component'
      ).then((m) => m.BryntumEventEditorComponent);
  }

  public importCalioPermissionModalComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/calio-permission-modal/calio-permission-modal.component'
      ).then((m) => m.CalioPermissionModalComponent);
  }

  public importMeetingSetupDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/meeting-setup/meeting-setup.component'
      ).then((m) => m.MeetingSetupComponent);
  }

  public importReleaseNotesDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/release-notes/release-notes-settings-form/release-notes-settings-form.component'
      ).then((m) => m.ReleaseNotesSettingsFormComponent);
  }

  public importPartnerReleaseNotesDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/release-notes/partner-release-notes-list/partner-release-notes-list.component'
      ).then((m) => m.PartnerReleaseNotesListComponent);
  }

  public importSmartWidgetFormDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/smart-widget-form/smart-widget-form.component'
      ).then((m) => m.SmartWidgetFormComponent);
  }

  public importQrCodeDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/qr-code-dialog/qr-code-dialog.component'
      ).then((m) => m.QrCodeDialogComponent);
  }

  public importSmartWidgetEmbedCodeDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/smart-widget-embed-code-dialog/smart-widget-embed-code-dialog.component'
      ).then((m) => m.SmartWidgetEmbedCodeDialogComponent);
  }

  public importTemplateRestrictionDialogComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/template-form-dialog/templates-restriction-form/templates-restriction-form.component'
      ).then((m) => m.TemplatesRestrictionFormComponent);
  }

  public importBookingQuestionOrderingComp(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/booking-question-ordering/booking-question-ordering.component'
      ).then((m) => m.BookingQuestionOrderingComponent);
  }

  public formFieldHistory(): () => Promise<any> {
    return () =>
      import(
        '../../calio-dynamic-lib/custom-form-field-history/custom-form-field-history.component'
      ).then((m) => m.CustomFormFieldHistoryComponent);
  }

  public auditLogsExport(): () => Promise<any> {
    return () =>
      import(
        '../../dashboard/settings/pages/audit-logs/export-logs/export-logs.component'
      ).then((m) => m.ExportLogsComponent);
  }
}
