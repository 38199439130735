import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {LOCAL_STORAGE_CONSTANT} from '../constants/localstorage.constant';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject('LOCALSTORAGE') private localStorage: any
  ) {
  }

  get(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      // Added seperate case for current language key
      if (key === LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY) {
        // Fetch value for both with and without version, first preference will
        // be given to without version key value
        const woVersion = this.localStorage.getItem(key );
        const wVersion = this.localStorage.getItem(key + '_' + environment.version);
        return woVersion ? woVersion : wVersion;
      } else {
        return this.localStorage.getItem(key + '_' + environment.version);
      }
    }
  }

  set(key: string, value: string) {
    if (isPlatformBrowser(this.platformId)) {
      // Added seperate case for current language key
      if (key === LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY) {
        // will not use versioning for language key, if present it will be
        // removed and without version will be set
        this.localStorage.removeItem(key + '_' + environment.version);
        this.localStorage.setItem(key, value);
      } else {
        this.localStorage.setItem(key + '_' + environment.version, value);
      }
    }
  }

  remove(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.removeItem(key + '_' + environment.version);
    }
  }

  clear() {
    if (isPlatformBrowser(this.platformId)) {
      const currentLang: string = this.get(LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY);
      const screenType: string = this.get(LOCAL_STORAGE_CONSTANT.CUSTOMER_LIST_VIEW_SELECTION);
      // LoggerService.log('currentLang ', currentLang);
      this.localStorage.clear();
      if (currentLang) {
        this.set(LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY, currentLang);
      }
      if (screenType) {
        this.set(LOCAL_STORAGE_CONSTANT.CUSTOMER_LIST_VIEW_SELECTION, screenType);
      }
    }
  }
}
