import {WorkerDbModel} from './worker-db.model';
import {CustomerDbModel} from './customer-db.model';
import {AppointmentServiceDbModel} from './appointment-service.model';
import {CustomerCustomField, CustomFieldModel} from './custom-field.model';
import {TemplateDbModel} from './template.model';

export class AppointmentCategory {
  id?: number;
  name?: string;
  html_color?: string;
}

export class CalendarEventQueryModel {
  worker_ids?: number[] = [];
  start?: string;
  end?: string;
  external?: number;
}

export class CalendarAppointmentModel {
  text?: string;
  start_date?: Date;
  end_date?: Date;
  class?: string;
  provider?: string;
  id?: string | number;
  etag?: string;
  href?: string;
  location?: string;
  description?: string;
  url?: string;
  worker_id?: number;
  resource_id?: number;
  readonly?: boolean;
  status?: string;
  child_appointments?: CalendarAppointmentModel[];
  start?: any;
  end?: any;
  title?: string;
  body?: string;
  uuid?: string;
  customer?: CustomerDbModel;
  broker?: BrokerDbModel;
  category?: AppointmentCategory;
  worker?: WorkerDbModel;
  google_api_key?: string;
  category_id: number;
  customer_id: number;
  user_notified: number;
  remind_customer?: number;
  appointment_source_id: number;
  cancellation_to: number;
  created: Date;
  read_only: number;
  external_calendar_id: number;
  external_calendar_type: string;
  slot_id: number;
  reminded: number;
  services: number[];
  selected_appointment_services: {
    id: number,
    appointment_service_id: number,
    appointment_id: number,
    appointment_service: AppointmentServiceDbModel
  }[] = [];
  IsReadonly: boolean;
  startTime: Date | string;
  endTime: Date | string;
  original_start: Date | string;
  original_end: Date | string;
  appointmentType: string;
  stripe_charge_id: number;
  charged_price: number;
  price: number;
  stripe_receipt_url: string;
  after_booking_message_received: number;
  parent_id: number;
  smart_action_2_notified: number;
  bexio_order_id: number;
  colors: CalioColors;
  supported_widget_languages: string[] = [];
  hash: string;
  courtesy_form: string;
  rebook_reason: string;
  rebook_date: Date;
  rebook_count: number;
  offer_delete_customer_data_option: number;
  is_appointment_rebooking_disabled: number;
  hide_book_additional_appointment_button: number;
  is_cancellation_feature_enabled: number;
  is_store_branding_feature_enabled: number;
  landingpage_hide_resource: number;
  landingpage_hide_services: number;
  landingpage_hide_location: number;
  landingpage_hide_partner_card: number;
  landingpage_allow_rebooking_for_other_workers: number;
  landingpage_logo: string;
  subscription_type_id: number;
  remarks: string;
  custom_fields: CustomerCustomField[];
  payment_type_id: number;
  templates: TemplateDbModel[] = [];
  landingpage_deactivate_rebooking_reason: number;
  landingpage_rebooking_reason_is_optional: number;
  landingpage_deactivate_cancellation_reason: number;
  landingpage_cancellation_reason_is_optional: number;
  meeting_type_id: number;
  future_booking_threshold_appointments?: number;
  is_customer_mobile_verified?: boolean;
  applied_coupon: string;
  coupon_reduction_amount: number;
  coupon_id: string;
  unblu_agent_desk_conversation_link: string;
  unblu_agent_single_view_conversation_link: string;
  unblu_conversation_id: string;
  unblu_visitor_desk_conversation_link: string;
  unblu_api_key: string;
  unblu_server_url: string;
  unblu_open_conversation: number;
  unblu_redirect_url: string;
  payment_type: string;
  enable_qrcode_feature: number;
  leadtime_appointment_rebooking: number;
  show_download_summary_button_on_landingpage: number;
  zoom_conference_password: string;
  zoom_host_url: string;
  deleted: Date;
  isRebookEnabled = false;
  isCancelledEnabled = false;
  cancellation_reason: string;
  send_appointment_confirmation = false;
  use_appointment_specific_custom_field_values: number;
  calenso_meet_customer_url: string;
  calenso_meet_host_url: string;
  internal_comment?: string;
  booker_worker_id: number;
  booker_worker: BookerWorkerModel;
}

export class BookerWorkerModel {
  full_name: string;
  lastname: string;
  prename: string;
  resource_name: string;
}

export class CalioColors {
  headerBackgroundColor?: string;
  backgroundColor?: string;
  headerFontColor?: string;
  headerTabColor?: string;
  tabContent?: string;
  companyBackgroundColor?: string;
  headerLinkFontColor?: string;
  headerTabColorActive?: string;
  pageTitleColor?: string;
}

export class CalendarWorker {
  id: number;
  name: string;
  role: string;
  imageUrl: string;
}

export class CalendarEvent {
  id: number | string;
  resourceId: number;
  name: string;
  desc: string;
  startDate: string;
  endDate: string;
  eventType: string;
  eventColor: string;
  iconCls: string;
}

export class CalendarEventCategory {
  id: number;
  html_color: string;
  name: string;
}

export class CalendarAuthenticationModel {
  worker_id: number;
  calendar_id: string;
  calendar_display_name: string;
  name: string;
  is_default: number;
  id: number;
  calendars: CalendarListModel[];
  resource_id: number;
  is_failed: number;
}

export class CalendarListModel {
  id: string;
  name: string;
  is_read_only: boolean;
  calendar_background_color: string;
  calendar_font_color: string;
  supports_teams_for_business: boolean;
  calendar_type: string;
  supports_google_meet: boolean;
}

export class FreeAppointmentDao {
  start: string;
  end: string;
  short_start: string;
  worker: WorkerDbModel;
  appointmentOrderIndex: number;
  startHour: number;
  isGrouped: boolean = Boolean(false);
}

export class AppointmentFilterQueryModel {
  appointment_service_ids?: number[];
  worker_ids?: number[];
  start?: Date | string;
  end?: Date | string;
  category_ids?: number[];
  price_start?: number = Number(0);
  price_end?: number = Number(1000);
  meeting_type_ids?: number[];
  booker_worker_ids?: number[];
  customer_ids?: number[];
  paid?: boolean;
  show_cancelled?: boolean;
  store_ids?: number[] = [];
  broker_ids?: number;
}

export class AppointmentDownloadFileModel extends AppointmentFilterQueryModel {
  type?: string;
}

export class BrokerDbModel {
  id: number;
  uuid: string;
  prename: string;
  lastname: string;
  street: string;
  zip: any;
  email: string;
  phone: string;
  city: string;
  partner_id: number;
  company: string;
}

export class AppointmentCustomFieldRequestBody {
  appointment_id: number;
  customFieldValues: CustomFieldValueRequestBody[] = [];
}

export class CustomFieldValueRequestBody {
  custom_field_id: number;
  value: any;
  type?: string;
  option_values?: {
    custom_field_option_id?: number,
    custom_field_option_value?: string
  }[] = [];
}
export class AppointmentCustomFieldDbModel {
  id: number;
  appointment_id?: number;
  custom_field_id: number;
  value: any;
  value_hash: string;
  contact_people_id: number;
  custom_field: CustomFieldModel;
  file: CustomAppointmentFieldFileDbModel;
  option_value: string;
  type: string;
  _history?: any;
  is_past_value: number;
}

export class CustomAppointmentFieldFileDbModel {
  id: number;
  filename: string;
  type: string;
  uuid: string;
  virus_check_performed: number;
  size: number;
  partner_id: number;
  appointment_id?: number;
  full_path: string;
  custom_field_id: number;
}
