import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TemplateDbModel} from '../db-models/template.model';
import {CalioDialogComponent} from '../../calio-lib/calio-dialog/calio-dialog.component';
import {PartnerSettingModel} from '../db-models/partner-payment-account.model';
import {
  CustomBookingDbModel,
  CustomerCustomFieldDbModel,
  CustomerDbModel,
  CustomerNotesDbModel,
  EventParticipantDbModel
} from '../db-models/customer-db.model';
import {CustomFieldModel, CustomFieldOptionModel} from '../db-models/custom-field.model';
import {DealsDbModel} from '../db-models/deals.model';
import {PartnerDbModel} from '../db-models/partner-db.model';
import {StripeCreditCardModel} from '../db-models/stripe-credit-card.model';
import {PartnershipDbModel} from '../db-models/partnership-db.model';
import {AppointmentServiceCategoryDbModel} from '../db-models/appointment-service-category-db.model';
import {TaxDbModel} from '../db-models/tax.model';
import {AppointmentServiceDbModel} from '../db-models/appointment-service.model';
import {WorkerDbModel} from '../db-models/worker-db.model';
import {StoreDbModel} from '../db-models/store-db.model';
import {ImportDynamicComponentService} from './import-dynamic-component.service';
import {CustomerTypeDbModel} from '../db-models/customer-type-db.model';
import {CategoryDbModel} from '../db-models/category-db.model';
import {
  FeatureProposalCommentDbModel,
  FeatureProposalDbModel,
  FeatureProposalVoteDbModel
} from '../db-models/feature-proposal-db';
import {CalendarAppointmentModel} from '../db-models/calenadar-appointment';
import {EventDbModel, EventSlotDbModel} from '../db-models/event-db.model';
import {LoggerService} from './logger.service';
import {WidgetAppointmentGroupModel} from '../db-models/widget-appointment-group.model';
import {AvailabilityDbModel} from '../db-models/availability-db.model';
import {PartnerReleaseNotesModel, ReleaseNotesModel} from '../db-models/release-notes.model';
import {BryntumCalendarEventModel, BryntumCalendarResourceModel} from '../client-models/bryntum-calendar-model';
import {SmartWidgetModel, SmartWidgetStepModel} from '../db-models/smart-widget.model';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private matDialog: MatDialog,
    private importDynamicComponentService: ImportDynamicComponentService,
  ) {
  }

  openTemplateEventDialog(data: {
    template?: TemplateDbModel,
    activeSubscriptionId?: number,
    templateType?: string,
    appointmentSubjectTemplate?: TemplateDbModel,
    eventSubjectTemplate?: TemplateDbModel,
    appointmentMailTemplate?: TemplateDbModel,
    eventMailTemplate?: TemplateDbModel,
    showTemplateTypeSwitcher?: boolean,
    showTestEmailButton?: boolean
  }): MatDialogRef<any> {
    let title: string;
    if (data.template && data.template.type === 'email') {
      title = 'templates_component.set_template';
    } else if (data.template && data.template.type === 'sms') {
      title = 'templates_component.set_template';
    } else if (data.template && data.template.type === 'general') {
      title = 'templates_component.set_template';
    } else if (data.templateType === 'complete-email') {
      title = 'templates_component.set_template';
    }
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importTemplateFormDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '550px',
    });
    return dialogRef;
  }

  openCalioSettingsDialog(partnerSettings: PartnerSettingModel[]): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCalioSettingFormComp(),
        compData: {
          partnerSettings
        },
        dialogTitle: 'calio_settings_component.dashboard_settings',
      },
      panelClass: 'calenso-generic-dialog-box',
      maxWidth: '500px'
    });
    return dialogRef;
  }

  openCustomerNoteFormDialog(customerNote: CustomerNotesDbModel): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCustomerNotesFormComp(),
        compData: {
          customerNote
        },
        dialogTitle: 'crm_component.customer_name_title',
      },
      panelClass: 'calenso-generic-dialog-box',
      maxWidth: '450px',
    });
    return dialogRef;
  }

  openCreateSettingDialog(compData: {
    [key: string]: string | number
  }): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCreateSettingComponent(),
        compData,
        dialogTitle: 'crm_component.mps_create_title',
      },
      panelClass: 'calenso-generic-dialog-box',
      maxWidth: '450px',
    });
  }

  openQuestionFieldFormDialog(
    data: {
      customFieldDbModel: CustomFieldModel,
      formType: string
    }, title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importQuestionFieldFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '450px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openDealFormDialog(
    data: {
      deal: DealsDbModel,
      formType: string
    }, title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importDealFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openCompanySettingTranslationFormDialog(partnerDbModel: PartnerDbModel): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCompanySettingTranslationFormComp(),
        compData: {
          partnerDbModel
        },
        dialogTitle: 'settings_component.company_description_title',
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openStripeCardDialog(stripeCardModel: StripeCreditCardModel, dialogTitle: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importStripeCardDialogComp(),
        compData: {
          stripeCardModel
        },
        dialogTitle,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openResellerInviteDialog(partnershipDB: PartnershipDbModel): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importResellerInviteComp(),
        compData: {
          partnershipDB
        },
        dialogTitle: 'reseller_component.reseller_invite_title',
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openServiceCategoryDialog(data: {
    showCancelButton: boolean, formType: string, appointmentServiceCategoryDbModel: AppointmentServiceCategoryDbModel
  },                        title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importServiceCategoryComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openAppointmentServiceDialog(data: {
    // taxes: TaxDbModel[],
    // appointmentServiceCategories: AppointmentServiceCategoryDbModel[],
    appointmentServiceDbModel: AppointmentServiceDbModel,
    formType: string,
    leadFollowUp: boolean,
  },                           title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importAppointmentServiceComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openAppointmentQuestionServiceDialog(data: {
    appointmentServiceDbModel: AppointmentServiceDbModel
  }): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importAppointmentServiceQuestionComp(),
        compData: data,
        dialogTitle: 'appointment_services_questions_component.title',
        dialogSubTitle: 'event_question_fields_component.you_manage_appointment_service',
        dialogSubtitleTranslationJson: {appointment_service: data.appointmentServiceDbModel.name}
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openEmployeeFormDialog(data: {
    // storeWorker?: { key: string, value: WorkerDbModel[], temp: any },
    formType?: string,
    worker?: WorkerDbModel,
    workerDbModels?: WorkerDbModel[]
  }): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEmployeeFormComp(),
        compData: data,
        // dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openStoresFormDialog(
    data: {
      formType: string,
      storeDbModel: StoreDbModel,
    },
    title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importStoreFormComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openWidgetAppointmentGroupFormDialog(data: {
    formType: string,
    widgetAppointmentGroupDbModel: WidgetAppointmentGroupModel,
  },                                   title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importWidgetAppointmentGroupFormComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openCalendarSetupDialog(
    data: {
      code?: string,
      calendarType?: string,
      workerId?: number,
      showWorkerOptionsInForm?: boolean,
      redirectTo?: boolean,
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCalendarSetupDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openRecurringAvailabilitiesFormDialog(data: {
    worker: WorkerDbModel,
    screenType: string,
    availability?: AvailabilityDbModel,
    formType: string,
    showWorkerOptionsInForm: boolean,
  },                                    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importAvailabilitiesFormSwitcherComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openAbsensesFormDialog(
    data: {
      worker: WorkerDbModel,
      partnerDbModel: PartnerDbModel,
      showWorkerOptionsInForm: boolean,
    },
    title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importAbsensesFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '550px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openCustomerTypesFormDialog(data: {
    formType: string,
    customerTypeDbModel: CustomerTypeDbModel,
  },                          title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCustomerTypesFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openCalendarTypesFormDialog(data: {
    formType: string,
    categoryDbModel: CategoryDbModel,
  },                          title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCreateCalendarTypeComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openPartnerSettingDialog(data: {
    setting_id?: number,
    template_type?: string,
    appointment_setting_id?: number,
    appointment_title?: string,
    event_setting_id?: number,
    placeholder?: string,
    event_title?: string,
    showAppointmentEventSwitch?: boolean,
    lead_time_setting_id?: number,
    lead_time_title?: string,
  },                       title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importPartnerSettingModalComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openJsonDiffViewerDialog(
    data: {
      json: {
        [key: string]: string
      },
      showJsonParser: boolean,
      showLeftOnly?: boolean
  },
    title: string
  ) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importJsonDiffViewerComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '700px',
    });
    return dialogRef;
  }

  openJsonViewerDialog(data: {
    json: string,
    showJsonParser: boolean
  },                   title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importJsonViewerComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '700px',
    });
    return dialogRef;
  }

  openTaxesFormDialog(data: {
    formType: string,
    taxDbModel: TaxDbModel
  },                  title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importTaxesFormComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openConnectStripeStepperDialog(data: {
    isStripeEnabled: boolean,
    isDialogLayout: boolean
  },                             title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importConnectStripeStepperComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '610px',
      disableClose: true,
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openCalensoWidgetDialog(data: {
    partnerBookingName: string,
    secret: string,
    internal: boolean,
    loggedInWorker?: WorkerDbModel,
    customer?: CustomerDbModel,
  },                      title: string, customHeaderClass: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCalensoWidgetModalComp(),
        compData: data,
        dialogTitle: title,
        customHeaderClass,
      },
      panelClass: 'calenso-widget-dialog-box',
      width: '1000px',
    });
    return dialogRef;
  }

  openFeatureProposalDialog(data: {
    formType?: string,
    featureProposalDbModel?: FeatureProposalDbModel,
    featureProposalVoteDbModel?: FeatureProposalVoteDbModel,
    featureProposalCommentDbModel?: FeatureProposalCommentDbModel,
  },                        title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importFeatureProposalDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openFeatureProposalAnonymousUserFormDialog() {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importFeatureProposalAnonymousUserFormComp(),
        compData: {},
        dialogTitle: 'feature_proposal_component.anonymous_user_dialog_title',
        dialogSubTitle: 'feature_proposal_component.anonymous_user_dialog_subtitle',
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openRebookAppointmentDialog(data: {
    uuid: string,
    appointment: CalendarAppointmentModel,
    partner: PartnerDbModel,
    userMode: string,
  }) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importRebookAppointmentComp(),
        compData: data,
        dialogTitle: 'appointment_detail_component.rebook_appointment',
      },
      width: '820px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openDeleteEventConfirmationDialog(data: {
    confirmationMsg?: string,
    confirmBtn?: string,
    cancelBtn?: string,
    showToggleButton?: boolean,
    toggleButtonTitle?: string,
    showCancelReasonTextArea?: boolean,
    cancelReason?: string,
    isCancelReasonRequired?: boolean,
    cancelReasonHelperText?: string,
    originalEventName?: string,
  },                                title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importDeleteEventConfirmationModalComp(),
        compData: data,
        dialogTitle: title,
      },
      maxWidth: '500px',
      // panelClass: 'rebook-appointment-detail-dialog-box',
    });
    return dialogRef;
  }

  openConfirmationDialog(data: {
    confirmationMsg?: string,
    confirmBtn?: string,
    cancelBtn?: string,
    showToggleButton?: boolean,
    toggleButtonTitle?: string,
    showCancelReasonTextArea?: boolean,
    cancelReason?: string,
    isCancelReasonRequired?: boolean,
    cancelReasonHelperText?: string,
    translateConfirmationMsg?: boolean
    translateToggleButtonMsg?: boolean,
    returnDataOnNo?: boolean
  }, title?: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importConfirmationModalComp(),
        compData: data,
        dialogTitle: title,
      }
    });
    return dialogRef;
  }

  openAppointmentFormDialog(data: {
    appointment: CalendarAppointmentModel,
    workers: WorkerDbModel[]
  }, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        compData: data,
        dialogTitle: title,
      },
      width: '500px',
      panelClass: 'calenso-generic-dialog-box',
    });
    return dialogRef;
  }

  openEventSlotParticipantDialog(data: {
    participants: {
      regular: EventParticipantDbModel[],
      waitingList: EventParticipantDbModel[]
    },
    slot: EventSlotDbModel,
    event: EventDbModel
  },                             title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEventSlotParticipantComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      // height: '1000px',
      width: '800px',
    });
    return dialogRef;
  }

  openEventSlotEditDialog(data: {
    slot: EventSlotDbModel,
    event: EventDbModel
  },                      title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEventSlotEditComp(),
        compData: data,
        dialogTitle: title,
      },
      // panelClass: 'event-participant-bottom-sheet',
      // height: '1000px',
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
    });
    return dialogRef;
  }

  openSmartActionTriggerDialog(data: {
    smartActionTrigger: PartnerSettingModel
  },                           title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importSmartActionTriggerComp(),
        compData: data,
        dialogTitle: title,
      },
      // panelClass: 'event-participant-bottom-sheet',
      // height: '1000px',
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
    });
    return dialogRef;
  }

  openImageUploadDialog(data: {
    imageUploadType?: string,
    workerDbModel?: WorkerDbModel
  },                    title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importImageUploadDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
    });
    return dialogRef;
  }

  openDeleteAccountConfirmationDialogComp(data: {}, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importDeleteAccountConfirmationDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      // width: '340px'
    });
    return dialogRef;
  }

  openCustomerBookingDeleteDialog(data: {
    bookings: CustomBookingDbModel[],
    landingpageDeactivateCancellationReasonFeature: PartnerSettingModel,
    landingpageCancellationReasonIsOptionalFeature: PartnerSettingModel
  },                              title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCustomerBookingDeleteComp(),
        compData: data,
        dialogTitle: title,
      },
      // width: '340px'
    });
    return dialogRef;
  }

  openEventCopyCodeDialog(data: {
    config: {
      imagePath: string,
      imageWidth: number,
      localeTitleKey: string,
      localeDescKey: string,
      links: [{
        displayScriptCode: string,
        displayLinkCode: string,
        codeToCopy: string,
      }]
    }
  },                      title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEventCopyCodeComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
      // width: '340px'
    });
    return dialogRef;
  }

  openEventAddSlotDialog(data: {}, title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importEventAddSlotBottomSheetComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '560px',
    });
    return dialogRef;
  }

  openBryntumCalendarEventEditorDialog(data: {
    eventRecord: BryntumCalendarEventModel,
    resourceRecord: BryntumCalendarResourceModel,
    eventStore: any,
    workers: WorkerDbModel[],
    partnerSettings: PartnerSettingModel[]
  },                                   title: string) {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importBryntumEventEditorComp(),
        compData: data,
        dialogTitle: title,
      },
      disableClose: false,
      autoFocus: true,
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
    });
    return dialogRef;
  }


  /*  openBryntumEventEditorDialog(data: {
      eventRecord: BryntumEventModel,
      resourceRecord: BryntumResourceModel,
      eventStore: any,
      workers: WorkerDbModel[]
    }, title: string) {
      const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
        data: {
          loadComponent: this.importDynamicComponentService.importBryntumEventEditorComp(),
          compData: data,
          dialogTitle: title,
        },
        disableClose: false,
        autoFocus: true,
        width: '500px',
      });
      return dialogRef;
    }*/

  openCalioPermissionModal() {
    LoggerService.log('inside openCalioPermissionModal');
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importCalioPermissionModalComp(),
        compData: {},
        dialogTitle: 'Permission Management',
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px'
    });
    return dialogRef;
  }

  openMeetingSetupDialog(
    data: {
      code?: string,
      meetingType?: string,
      workerId?: number,
      eventId?: number,
      showWorkerOptionsInForm?: boolean,
      showEventOptionsInForm?: boolean,
      redirectTo?: number,
      source?: string
    },
    title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importMeetingSetupDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openReleaseNotesDialog(
    data: {
      releaseNotes: ReleaseNotesModel,
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importReleaseNotesDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openPartnerReleaseNotesDialog(
    data: {
      releaseNotes: PartnerReleaseNotesModel[]
    },
    title: string, subtitle: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importPartnerReleaseNotesDialogComp(),
        compData: data,
        dialogTitle: title,
        dialogSubTitle: subtitle,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openSmartWidgetFormDialog(
    data: {
      smartWidgetData: SmartWidgetModel,
      formType: string
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importSmartWidgetFormDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openQrCodeDialog(
    data: {
      uuid: string
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importQrCodeDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openSmartWidgetEmbedCodeDialog(
    data: {
      widgetUUID: string,
      partnerUUID: string,
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importSmartWidgetEmbedCodeDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openSmartWidgetStepFormDialog(data: {
    formType: string,
    smartWidgetStepModel: SmartWidgetStepModel,
  },                            title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importSmartWidgetStepFormComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '500px',
    });
    return dialogRef;
  }

  openTemplateRestrictionDialog(
    data: {},
    title: string,
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importTemplateRestrictionDialogComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openBookingQuestionOrderingDialog(
    data: {
      rawTitle: string,
      selectedTitle: string,
      pageType: string,
      selectedFormFields: CustomFieldModel[],
      deSelectedFormFields: CustomFieldModel[],
      stepDbModel?: SmartWidgetStepModel,
    },
    title: string,
  ): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.importBookingQuestionOrderingComp(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openHistoryDialog(
    data: {
      history: CustomerCustomFieldDbModel[]
      customFields: CustomFieldOptionModel[]
      customFieldType: string
    },
    title: string): MatDialogRef<CalioDialogComponent, any> {
    const dialogRef: MatDialogRef<CalioDialogComponent, any> = this.matDialog.open(CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.formFieldHistory(),
        compData: data,
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
    return dialogRef;
  }

  openAuditLogsExportDialog(
    title: string
  ): MatDialogRef<CalioDialogComponent, any> {
    return this.matDialog.open(
      CalioDialogComponent, {
      data: {
        loadComponent: this.importDynamicComponentService.auditLogsExport(),
        compData: {},
        dialogTitle: title,
      },
      panelClass: 'calenso-generic-dialog-box',
      width: '520px',
    });
  }
}
