import {EventEmitter, Injectable} from '@angular/core';
import {BreadCrumb} from "../client-models/breadcrumb.model";
import {SmartWidgetModel, SmartWidgetStepModel} from '../db-models/smart-widget.model';
import {CustomFieldModel} from '../db-models/custom-field.model';

@Injectable({
  providedIn: 'root'
})
export class CalioEventsService {

  public addEventSlotsEvent: EventEmitter<any> = new EventEmitter();

  public toggleMobileMenu: EventEmitter<boolean> = new EventEmitter();

  public closeMobileMenu: EventEmitter<boolean> = new EventEmitter();

  public hideHeaderEvent: EventEmitter<boolean> = new EventEmitter();

  public hideGlobalSearchEvent: EventEmitter<boolean> = new EventEmitter();

  public gotoSettingsRightSectionEvent: EventEmitter<any> = new EventEmitter();

  public closeCalioDialogEvent: EventEmitter<any> = new EventEmitter<any>();

  public onToggleButton: EventEmitter<any> = new EventEmitter<any>();

  public calioDialogTitleChangedEvent: EventEmitter<{
    title?: string,
    subtitle?: string
  }> = new EventEmitter<any>();

  public calioDashboardHeaderDataChanged: EventEmitter<{
    title: string,
    subtitle: string,
    breadcrumbs: BreadCrumb[],
    permissionType?: string,
    showLastItemCustomLabel?: boolean,
    lastItemCustomLabel?: string,
    isTitleNotTranslatable?: boolean,
    permissionTypes?: string[],
    checkForMultiplePermissions?: boolean
  }> = new EventEmitter();

  public resetIsMultiDayToggleButton: EventEmitter<any> = new EventEmitter<any>();

  public resetWebsiteIntegrationWidgetConfigEvent: EventEmitter<any> = new EventEmitter<any>();

  public updateSmartWidgetEvent: EventEmitter<{
    smartWidgetData: SmartWidgetModel
  }> = new EventEmitter();

  public nextStepperStepEvent: EventEmitter<{
    smartWidgetData: SmartWidgetModel
  }> = new EventEmitter();

  public previousStepperStepEvent: EventEmitter<{
    smartWidgetData: SmartWidgetModel
  }> = new EventEmitter();

  public bookingQuestionReorderingEvent: EventEmitter<{
    selectedCustomFields: CustomFieldModel[],
    pageType: string,
    stepDbModel?: SmartWidgetStepModel,
  }> = new EventEmitter();

  constructor() {

  }
}
