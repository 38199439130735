import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-saferpay',
  templateUrl: './saferpay.component.html',
  styleUrls: ['./saferpay.component.scss']
})
export class SaferpayComponent implements OnInit {
  paymentSuccess: boolean;

  constructor(
    private route: ActivatedRoute
  ) {
    this.paymentSuccess = ((this.route.snapshot.params?.status === 'success') ? true : false);
  }

  ngOnInit(): void {
  }

}
