import {Injectable} from '@angular/core';
import {NativeDateAdapter} from "@angular/material/core";
import {formatDate} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CalioDateAdapterService extends NativeDateAdapter {

  getFirstDayOfWeek(): number {
    return 1;
  }

  format(date: Date, displayFormat: Object): string {
    /*if (displayFormat === 'input') {
      return formatDate(date,'dd.MM.yyyy',this.locale);;
    } else {
      return date.toDateString();
    }*/
    return formatDate(date, 'dd.MM.yyyy', this.locale);
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return super.getDayOfWeekNames('short');
  }


}
