export class TaxDbModel {
  id: number;
  created: Date | string;
  valid_from: Date | string;
  valid_until: Date | string;
  partner_id: number;
  rate: number;
  description: string;
  region: string;
  is_default: number;
  stripe_tax_id?: string;
}
