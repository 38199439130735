<div *ngIf="title" class="{{ customHeaderClass }}">
  <div class="d-flex justify-content-between align-items-center">
    <div class="title">
      {{ title | translate }}
    </div>
    <button type="button" class="close" aria-label="Close" (keydown.enter)="$event.preventDefault()" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="subtitle">
    {{ subtitle | translate: subtitleTranslationJson }}
  </div>
</div>

<hr *ngIf="title" class="separator"/>

<ng-template appDynamicDirective></ng-template>
