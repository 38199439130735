import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, from, Observable, of, throwError} from 'rxjs';
import createAuth0Client, {Auth0Client, RedirectLoginResult} from '@auth0/auth0-spa-js';
import {catchError, concatMap, retry, shareReplay, switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {PartnerService} from './partner.service';
import {WorkerService} from './worker.service';
import {StartSiteService} from './start-site.service';
import {LOCAL_STORAGE_CONSTANT} from '../constants/localstorage.constant';
import {LocalStorageService} from '../util-services/local-storage.service';
import {CryptoService} from '../util-services/crypto.service';
import {HttpErrorResponse} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {LoggerService} from '../util-services/logger.service';
import {AlertToastrService} from '../util-services/alert-toastr.service';
import {DialogService} from '../util-services/dialog.service';
import {ReleaseNotesService} from './release-notes.service';
import {PartnerDbModel} from '../db-models/partner-db.model';
import {PartnerReleaseNotesModel} from '../db-models/release-notes.model';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {

  userIsLoggedInEvent: EventEmitter<any> = new EventEmitter();
  workerAvatarEvent: EventEmitter<string> = new EventEmitter();

  // Create an observable of Auth0 instance of client
  auth0Client$: Observable<Auth0Client> = (from(
    createAuth0Client({
      domain: 'calenso.eu.auth0.com',
      client_id: '2f4D07jLoq7y4353XUEkiSwZP4wanDtu',
      redirect_uri: `${window.location.origin}/authorize`,
      // redirect_uri: `${window.location.href}`,
      audience: 'https://my.calenso.com/api/v1',
      useRefreshTokens: true,
      cacheLocation: 'localstorage'
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );

  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$: Observable<boolean> = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );

  handleRedirectCallback$: Observable<RedirectLoginResult> = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  userProfile$: Observable<any> = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  queryparams: {
    [name: string]: string;
  };

  constructor(
    private router: Router,
    private partnerService: PartnerService,
    private workerService: WorkerService,
    private startSiteService: StartSiteService,
    private localStorageService: LocalStorageService,
    private cryptoService: CryptoService,
    public jwtHelperService: JwtHelperService,
    private alertToastrService: AlertToastrService,
    private dialogService: DialogService,
    private releaseNotesService: ReleaseNotesService,
  ) {
    this.queryparams = window.location.search.substr(1).split('&').reduce(function (q, query) {
      const chunks = query.split('=');
      const key = chunks[0];
      const value = chunks[1];
      return (q[key] = value, q);
    }, {});

    LoggerService.log('queryparams ', this.queryparams);

    /* LoggerService.log('this.route ', this.route);
     LoggerService.log('this.queryParams.error ', this.route.snapshot.queryParams);
     LoggerService.log('this.queryParams.error ', this.route.snapshot.queryParamMap);
     this.queryParams.error = this.route.snapshot.queryParams.error;
     this.queryParams.errorDesc = this.route.snapshot.queryParams.error_description;
     LoggerService.log('this.queryParams.error ', this.queryParams.error);
     LoggerService.log('this.queryParams.errorDesc ', this.queryParams.errorDesc);

     LoggerService.log('this.queryParams.error ', this.route.snapshot.queryParams['error']);
     LoggerService.log('this.queryParams.errorDesc ', this.route.snapshot.queryParams['error_description']);*/
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user)),
      catchError((e: Error) => {
        LoggerService.log('Inside error block 3');
        LoggerService.error(e);
        // throw e;
        return of(null);
      })
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$: Observable<any> = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe(
      (result: any) => {
        // LoggerService.log('result is ', result);
        if (result) {
          // User is already logged in

        } else {
          LoggerService.log('decodeURIComponent(this.queryparams[\'error\']) ', decodeURIComponent(this.queryparams['error']));
          LoggerService.log('decodeURIComponent(this.queryparams[\'error_description\']) ', decodeURIComponent(this.queryparams['error_description']));
          if (this.queryparams && this.queryparams['error'] === 'unauthorized') {
            LoggerService.log('User is blocked and redirect to login page.');
            this.alertToastrService.showError('general.user_is_blocked');
            setTimeout(() => {
              this.login();
            }, 2000);
          } else if (this.queryparams && this.queryparams['error'] === 'access_denied' && this.queryparams['error_description'] === 'User did not authorize the request') {
            LoggerService.log('User is unauthorized and redirect to login page.');
            this.alertToastrService.showError('general.user_is_unauthorised');
            setTimeout(() => {
              this.login();
            }, 2000);
          } else if (this.queryparams && this.queryparams['error'] === 'access_denied' && this.queryparams['error_description'] === 'Request to Webtask exceeded allowed execution time') {
            LoggerService.log('Request to Webtask exceeded allowed execution time and redirect to login page.');
            this.alertToastrService.showError('general.request_to_webtask_exceeded');
            setTimeout(() => {
              this.login();
            }, 2000);
          } else if (this.queryparams && this.queryparams['error']) {
            LoggerService.log('Auth0 other errors.');
            this.alertToastrService.showErrorMessageWithoutTranslation(decodeURIComponent(this.queryparams['error_description']));
            setTimeout(() => {
              this.login();
            }, 2000);
          }
          // this.logout();
          // this.router.navigate(['/login']);
          // window.location.href = window.location.origin + '/login';
        }
      },
      (error: HttpErrorResponse) => {
        LoggerService.log('Inside error block 1');
        LoggerService.error(error);
        // this.logout();
      }
    );
  }

  login(redirectPath: string = '/', ignoreWindowLocationPath?: boolean) {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    LoggerService.log('login called');
    if (ignoreWindowLocationPath) {
      LoggerService.log('redirectPath ', redirectPath);
      this.auth0Client$.subscribe((client: Auth0Client) => {
        // Call method to log in
        client.loginWithRedirect({
          redirect_uri: `${window.location.origin}/authorize`,
          appState: {
            target: redirectPath
          }
        });
      });
    } else {
      redirectPath = window.location.pathname;
      const search: string = window.location.search;
      LoggerService.log('redirectPath ', redirectPath);
      this.auth0Client$.subscribe((client: Auth0Client) => {
        // Call method to log in
        client.loginWithRedirect({
          redirect_uri: `${window.location.origin}/authorize`,
          appState: {
            target: redirectPath,
            queryParams: search
          }
        });
      });
    }
  }

  private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    LoggerService.log('params ', params);
    if (params.includes('code=') && params.includes('state=')) {
      LoggerService.log('Inside if statement');
      let targetRoute: string; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          LoggerService.log('Inside tap ', cbRes);
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
          let queryParams: string = cbRes.appState && cbRes.appState.queryParams ? cbRes.appState.queryParams : '';
          LoggerService.log('Query Params are ', queryParams);
          targetRoute = targetRoute + queryParams;
        }),
        concatMap(() => {
          LoggerService.log('Inside concat ');
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getUser$(),
            this.getTokenSilently$(),
            this.isAuthenticated$,
          ]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.pipe(
        (switchMap(([user, accessToken, loggedIn]) => {
          // Redirect to target route after callback processing
          this.resetLocalStorage();
          LoggerService.log('user ', user);
          const auth0Id: string = user.sub;
          LoggerService.log('worker auth0 id ', auth0Id);
          LoggerService.log('loggedIn ', loggedIn);
          LoggerService.log('targetRoute ', targetRoute);
          // LoggerService.log('dashboardData ', partnerData);
          if (!loggedIn) {
            // TODO If user is not logged-in then handle this case
            LoggerService.log('If user is not logged-in then handle this case');
          }
          LoggerService.log('accessToken ', accessToken);
          const decodedJwt: any = this.jwtHelperService.decodeToken(accessToken);
          LoggerService.log('decodedJwt ', decodedJwt);

          if (decodedJwt && decodedJwt.permissions && decodedJwt.permissions.length > 0) {
            LoggerService.log('permissions ', decodedJwt.permissions);
            this.setUserPermissions(decodedJwt.permissions);
          } else {
            this.alertToastrService.showError('general.user_roles_not_defined');
            setTimeout(() => {
              this.logout();
            }, 2000);
            throw new Error('User permissions are not defined');
          }

          const d: Date = new Date();
          return combineLatest([
            this.partnerService.initializeDashboard(),
            this.workerService.setLoggedInUserInLocalStorage(auth0Id),
            this.startSiteService.getStartSiteStatsByYear(d.getFullYear()),
            this.partnerService.validateConfig()
          ]);
        })),
      )
        .subscribe(([partnerData, worker, statisticsData]) => {
            LoggerService.log('dashboardData ', partnerData);
            this.localStorageService.set(LOCAL_STORAGE_CONSTANT.AVATAR_KEY, worker.avatar);
            const totalBookings: number = statisticsData.total_bookings_ever.appointments + statisticsData.total_bookings_ever.events;
            this.localStorageService
              .set(LOCAL_STORAGE_CONSTANT.CURRENT_NUMBER_OF_BOOKINGS_KEY, this.cryptoService.encryptValue(totalBookings.toString()));
            if (this.partnerService.getPartnerFromLocalStorage()
              && this.partnerService.getPartnerFromLocalStorage().accept_terms_of_services === 0) {
              this.router.navigateByUrl('/app/setup').then((value: boolean) => {
                LoggerService.log('After routing to /app/setup ');
                this.openPartnerReleaseNotesDialog(this.partnerService.getPartnerFromLocalStorage());
              });
            } else {
              this.router.navigateByUrl(targetRoute).then((value: boolean) => {
                LoggerService.log('After routing to targetRoute ', targetRoute);
                this.openPartnerReleaseNotesDialog(this.partnerService.getPartnerFromLocalStorage());
              });
            }
            this.userIsLoggedInEvent.emit(worker);
          },
          (error: HttpErrorResponse) => {
            // TODO We need to check what we can do about this error handler
            LoggerService.log('Inside error block 2');
            LoggerService.error(error);
            LoggerService.log(error.message);
            LoggerService.log(error.error);
            LoggerService.log(error.status);

            // this.alertToastrService.showError('login_component.login_failed');
          }
        );
    }
  }

  openPartnerReleaseNotesDialog(partner: PartnerDbModel) {
    LoggerService.log('Inside openPartnerReleaseNotesDialog');
    // LoggerService.warn('CALENSO:WARN -> Partner has release notes ', partner.has_release_notes);
    if (this.checkRolesPermission('read:partners-release-notes') && partner.has_release_notes) {
      LoggerService.log('Inside openPartnerReleaseNotesDialog 1');
      this.releaseNotesService.getPartnerReleaseNotes().subscribe(
        (releaseNotes: PartnerReleaseNotesModel[]) => {
          LoggerService.log('Inside openPartnerReleaseNotesDialog 2 ', releaseNotes);
          if (!releaseNotes) {
            releaseNotes = [];
          }
          if (releaseNotes?.length > 0) {
            this.dialogService.openPartnerReleaseNotesDialog({
                releaseNotes
              },
              'release_notes_comp.release_notes_card_title',
              'release_notes_comp.partner_release_notes_dialog_subtitle'
            ).afterClosed().subscribe(
              (result: any) => {
                this.deletePartnerReleaseNote();
              }
            );
          }
        },
        (error: HttpErrorResponse) => {
          LoggerService.log('Error is ', error);
        }
      );
    }
  }

  /*  getWorkerIdFromUserSub(sub: string): number {
      if (sub) {
        let subList: string[] = sub.split('|');
        return Number(subList[1]);
      }
      return undefined;
    }*/

  getTokenSilently$(options?): Observable<string> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options))),
      retry(2),
      catchError((error: HttpErrorResponse) => {
        LoggerService.log('Inside error block 4');
        LoggerService.error(error);
        LoggerService.log(JSON.stringify(error));
        LoggerService.log('Inside catch error ', error);
        LoggerService.log(error.message);
        LoggerService.log(error.error);
        LoggerService.log(error.status);
        // throw e;
        return of(null);
      })
    );
  }

  resetLocalStorage() {
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SHORT_APPOINTMENT_SERVICE_LIST_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SHORT_WORKER_LIST_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SCHEDULER_ABSENCES_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SCHEDULER_AVAILABILITES_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.WORKERS_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.LOGGED_IN_WORKER);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.USER_PERMISSIONS);
  }

  setUserPermissions(permissions: string[]): void {
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.USER_PERMISSIONS, this.cryptoService.encryptValue(JSON.stringify(permissions)));
  }

  getUserPermissions(): string[] {
    const encryptedString: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.USER_PERMISSIONS);
    if (encryptedString) {
      const decryptedString: string = this.cryptoService.decryptValue(encryptedString);
      return JSON.parse(decryptedString);
    } else {
      return [];
    }
  }

  checkRolesPermission(value: string | string[], isArray?: boolean): boolean {
    // LoggerService.log('Permission to check ', value);
    const permissions: string[] = this.getUserPermissions();
    if (!isArray) {
      const userPermission: string = <string>value;
      if (permissions && permissions.indexOf(userPermission) > -1) {
        return true;
      }
    } else if (isArray) {
      const userPermissions: string[] = <string[]>value;
      const newPermissions: string[] = userPermissions.filter((item: string) => {
        return permissions.indexOf(item) > -1;
      });
      /*LoggerService.log('userPermissions ', userPermissions);
      LoggerService.log('newPermissions ', newPermissions);*/
      if (newPermissions && newPermissions.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  logout() {
    LoggerService.log('inside logout function');
    LoggerService.log('window.location.href ', window.location.href);
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      this.localStorageService.clear();
      client.logout({
        client_id: '2f4D07jLoq7y4353XUEkiSwZP4wanDtu',
        returnTo: `${window.location.origin}`,
        // returnTo: `${window.location.href}`,
      });
    });
  }

  deletePartnerReleaseNote() {
    this.releaseNotesService.deletePartnerReleaseNote().subscribe(
      (result: { success: boolean }) => {
        LoggerService.log('Release notes are deleted');
      },
      (error: HttpErrorResponse) => {
        console.error(error);
      }
    );
  }
}
