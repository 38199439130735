import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {FEATURE_PROPOSAL_URL} from '../constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class FeatureProposalGuardService implements CanActivate {
  constructor(
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.document.location.href = FEATURE_PROPOSAL_URL;
    return false;
  }
}
