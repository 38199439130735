import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {PartnerDbModel} from '../db-models/partner-db.model';
import {LoggerService} from './logger.service';
import {WorkerDbModel} from '../db-models/worker-db.model';
import {environment} from '../../../environments/environment';
import {Params} from '@angular/router';
import {APP_CONSTANTS} from '../constants/app.constants';
import {WidgetConfModel} from '../db-models/widget-conf.model';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  public renderer: Renderer2;
  public appConstants = APP_CONSTANTS;

  constructor(
    public translate: TranslateService,
    private rendererFactory2: RendererFactory2
  ) {
    this.renderer = this.rendererFactory2.createRenderer(null, null);
  }

  sort(collection: Array<any>): Array<any> {
    collection.sort((a: any, b: any) => {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }

  sortByNumber(collection: Array<any>, property: string): Array<any> {
    collection.sort((a: any, b: any) => {
      if (a[property] < b[property]) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }

  sortBy(collection: Array<any>, property: string): Array<any> {
    collection.sort((a: any, b: any) => {
      if ((a[property] ? a[property].toLowerCase() : undefined) < (b[property] ? b[property].toLowerCase() : undefined)) {
        return -1;
      } else if (a[property] > b[property]) {
        return 1;
      } else {
        return 0;
      }
    });
    return collection;
  }

  // Rounding the minute to 0, 5, 10, 15, 20
  public createRoundDate(d: Date, hours?: number): Date | string {
    const date: moment.Moment = moment(d);
    if (hours) {
      date.set({hour: hours, minute: 0});
    }
    const minutes: number = date.minutes();
    const mod: number = minutes % 10;
    let finalMinutes: number = minutes;
    if (mod < 5) {
      finalMinutes = minutes - mod;
    } else if (mod > 5) {
      finalMinutes = minutes - (mod - 5);
    }
    date.set('minutes', finalMinutes);
    return date.toDate();
  }

  dateAgoUtility(value: string): string {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) {
        return 'Just now';
      } // less than 30 seconds ago will show as 'Just now'
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
      };

      let counter;

      for (const i in intervals) {
        if (intervals[i]) {
          counter = Math.floor(seconds / intervals[i]);
          if (counter > 0) {
            if (counter === 1) {
              let translation: string = this.translate.instant(`notification_component.x_${i + '_ago'}`);
              // LoggerService.log('translation if is ', translation);
              translation = translation.replace('x ', counter + ' ');
              // LoggerService.log('final translation is ', translation)
              return translation; // singular (1 day ago)
            } else {
              let translation: string = this.translate.instant(`notification_component.x_${i + 's_ago'}`);
              // LoggerService.log('translation if is ', translation);
              translation = translation.replace('x ', counter + ' ');
              // LoggerService.log('final translation is ', translation)
              return translation; // plural (2 days ago)
            }
          }
        }
      }
    }
    return value;
  }

  public createListOfYearsOnAbsenseForm(currentYear: number): number[] {
    const listOfYears: number[] = [];
    // listOfYears.push(currentYear + 1);
    for (const i of [0, 1]) {
      listOfYears.push(currentYear + i);
    }
    return listOfYears;
  }

  public createListOfYears(currentYear: number): number[] {
    const listOfYears: number[] = [];
    const minYear = 2019;
    listOfYears.push(currentYear + 1);
    for (let index = 0; index <= (currentYear - minYear); index++) {
      listOfYears.push(currentYear - index);
    }
    return listOfYears;
  }

  isNotEmptyString(value: string): string {
    if (Array.isArray(value)) {
      return undefined;
    }

    if (!value || value?.trim() === '' || value === 'null' || value === null || value === undefined) {
      return undefined;
    }
    return value;
  }

  isEmptyString(value: string): string {
    if (value) {
      if (value.trim() === '') {
        return null;
      } else {
        return value;
      }
    } else {
      return null;
    }
  }

  public convertBooleanToNumber(value: number | boolean): number {
    if (value) {
      value = 1;
    } else {
      value = 0;
    }
    return value;
  }

  public replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  public getDefaultLanguage(
    localStorageLang: string,
    partnerData: PartnerDbModel,
    loggedInWorker: WorkerDbModel,
  ): string {
    let language: string;
    const userLang = navigator.language;

    LoggerService.log('inside getDefaultLanguage userLang ', userLang);
    LoggerService.log('loggedInWorker?.lang_identifier ', loggedInWorker?.lang_identifier);
    LoggerService.log('localStorageLang ', localStorageLang);

    if (localStorageLang) {
      language = localStorageLang;
    } else if (loggedInWorker && loggedInWorker.lang_identifier) {
      language = loggedInWorker.lang_identifier;
    } else {
      this.appConstants.LANGUAGES.forEach(languages => {
        if (userLang.includes(languages.value)) {
          language = languages.locale;
        }
      });
    }

    if (language === undefined) {
      if (partnerData && partnerData.language_identifier) {
        language = partnerData.language_identifier;
      } else {
        language = 'de_CH';
      }
    }

    return language;
  }

  getBookingPageLang(partnerData: PartnerDbModel): string {
    const userLang: string = navigator.language;
    LoggerService.log('partnerData ', partnerData);
    LoggerService.log('userLang ', userLang);
    let browserLang: string;
    const partnerWidgetLangs: string[] = [];
    if (partnerData && partnerData.supported_widget_languages && partnerData.supported_widget_languages.length > 0) {
      this.appConstants.LANGUAGES.forEach(languages => {
        if (partnerData.supported_widget_languages.includes(languages.value)) {
          partnerWidgetLangs.push(languages.locale);
        }
      });
    }

    const foundBrowserLang = this.appConstants.LANGUAGES.find(languages => userLang.includes(languages.value));
    if (foundBrowserLang){
      browserLang = foundBrowserLang.locale;
    } else {
      browserLang = 'de_CH';
    }

    if (partnerWidgetLangs.indexOf(browserLang) > -1) {
      return browserLang;
    } else {
      if (partnerWidgetLangs.length > 0) {
        return partnerWidgetLangs[0];
      } else {
        return browserLang;
      }
    }
  }

  getSurveyPageLang(widgetConf: WidgetConfModel): string {
    const userLang: string = navigator.language;
    let browserLang: string;
    const supportedLangs: string[] = [];
    if (widgetConf && widgetConf.supported_widget_languages && widgetConf.supported_widget_languages.length > 0) {
      this.appConstants.LANGUAGES.forEach(languages => {
        if (widgetConf.supported_widget_languages.includes(languages.value)) {
          supportedLangs.push(languages.locale);
        }
      });
    }

    const foundBrowserLang = this.appConstants.LANGUAGES.find(languages => userLang.includes(languages.value));
    if (foundBrowserLang){
      browserLang = foundBrowserLang.locale;
    } else {
      browserLang = widgetConf.locale;
    }

    if (supportedLangs.indexOf(browserLang) > -1) {
      return browserLang;
    } else {
      if (supportedLangs.length > 0) {
        return supportedLangs[0];
      } else {
        return browserLang;
      }
    }
  }

  public formatDate(date: string) {
    if (!date) {
      return null;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }

    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  isIEorEdgeBrowser(): boolean {
    if (window) {
      const isIE = /edge|msie\s|trident\//i.test(window.navigator.userAgent);
      return isIE;
    }
    return false;
  }

  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getTooltipUrl(key: string, lang: string): string {
    return environment.tooltipUrls[key][lang];
  }

  public removeDuplicates(arr, prop): any {
    LoggerService.log('Inside removeDuplicates');
    const obj = {};
    return Object.keys(arr.reduce((prev, next) => {
      if (!obj[next[prop]]) {
        obj[next[prop]] = next;
      }
      return obj;
    }, obj)).map((i) => obj[i]);
  }

  /**
   * Groups an array of objects with multiple properties.
   *
   * @param  {Array}  array: the array of objects to group
   * @param  {Array} props: the properties to groupby
   * @return {Array} an array of arrays with the grouped results
   */
  public groupBy(collection: any[], props: string[]) {
    const getGroupedItems = (item) => {
      const returnArray = [];
      let i;
      for (i = 0; i < props.length; i++) {
        returnArray.push(item[props[i]]);
      }
      return returnArray;
    };

    const groups = {};
    let i;

    for (i = 0; i < collection.length; i++) {
      const arrayRecord = collection[i];
      const group = JSON.stringify(getGroupedItems(arrayRecord));
      groups[group] = groups[group] || [];
      groups[group].push(arrayRecord);
    }
    return Object.keys(groups).map((group) => {
      return groups[group];
    });
  }

  getBrowserName(): string {
    if ((navigator.userAgent.indexOf('Opera') !== -1 || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) // IF IE > 10
    {
      return 'IE';
    } else {
      return 'unknown';
    }
  }

  validateEmail(email: string): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  public findStringBetween(str: string, char1: string, char2: string): string {
    return str.split(char1).pop().split(char2)[0];
  }

  scrollToElementById(
    id: string,
    enableHighlighting: boolean,
  ) {
    const element = document.getElementById(id);
    LoggerService.log('Inside scrollToId element ', element);
    if (element) {
      element.scrollIntoView({behavior: 'smooth'});

      if (enableHighlighting) {
        if (element.classList) {
          element.classList.add('calio-global-search-highlighter');
        }
      }
    }
  }

  getAllQueryParams(): Params {
    return window.location.search.substring(1).split('&').reduce((q, query) => {
      const chunks = query.split('=');
      const key = chunks[0];
      const value = chunks[1];
      return (q[key] = value, q);
    }, {});
  }
}
