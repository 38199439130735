import {EventDbModel} from './event-db.model';
import {CustomCustomFieldFileDbModel, CustomerCustomFieldDbModel} from './customer-db.model';

export class CustomFieldModel {
  id?: number;
  placeholder: string;
  label: string;
  name?: string;
  required: number;
  partner_id?: number;
  type: string;
  position: number;
  customer_custom_field_type_id?: number;
  sort_alphabetically?: number;
  alphabetically_sorted: number;
  apply_to_appointments: number;
  is_multiple_select: number;
  is_multiple_file: number;
  apply_to_events: number;
  is_read_only: number;
  is_end_of_process: number;
  is_hidden: number;
  hide_in_email: number;
  custom_field_dependency_enabled: number;
  apply_to_resource_types?: number;
  is_multi_language: number;
  custom_field_options?: CustomFieldOptionModel[];
  options?: CustomFieldOptionModel[];
  child_custom_field_id?: number;
  title: string;
  parent_custom_field_id?: number;
  child_event_id?: number;
  child_event?: EventDbModel;
  child_custom_field?: CustomFieldModel;
  depends_on_custom_field_id?: number;
  status: number;
  _translations?: {
    [key: string]: TranslationDescriptionModel
  };
  multiple: number;
  supported_mime_type: string | string[];
  format?: string;
  regex?: string;
  color?: string;
  _history?: CustomerCustomFieldDbModel[];
  show_history_button?: boolean;
  always_show?: number;
  customerSelectedValue?: any;
  customerSelectedFileValue?: any;
  customSupportedMimeType: string;

  public static updateCustomField(oldCustomField: CustomFieldModel, updatedCustomField: CustomFieldModel): void {
    oldCustomField.placeholder = updatedCustomField.placeholder;
    oldCustomField.label = updatedCustomField.label;
    oldCustomField.name = updatedCustomField.name;
    oldCustomField.required = updatedCustomField.required;
    oldCustomField.partner_id = updatedCustomField.partner_id;
    oldCustomField.type = updatedCustomField.type;
    oldCustomField.position = updatedCustomField.position;
    oldCustomField.customer_custom_field_type_id = updatedCustomField.customer_custom_field_type_id;
    oldCustomField.sort_alphabetically = updatedCustomField.sort_alphabetically;
    oldCustomField.alphabetically_sorted = updatedCustomField.alphabetically_sorted;
    oldCustomField.apply_to_appointments = updatedCustomField.apply_to_appointments;
    oldCustomField.apply_to_events = updatedCustomField.apply_to_events;
    oldCustomField.apply_to_resource_types = updatedCustomField.apply_to_resource_types;
    oldCustomField.is_multi_language = updatedCustomField.is_multi_language;
    oldCustomField.custom_field_options = updatedCustomField.custom_field_options;
    oldCustomField.options = updatedCustomField.options;
    oldCustomField.child_custom_field_id = updatedCustomField.child_custom_field_id;
    oldCustomField.title = updatedCustomField.title;
    oldCustomField.parent_custom_field_id = updatedCustomField.parent_custom_field_id;
    oldCustomField.child_event_id = updatedCustomField.child_event_id;
    oldCustomField.child_event = updatedCustomField.child_event;
    oldCustomField.child_custom_field = updatedCustomField.child_custom_field;
    oldCustomField.depends_on_custom_field_id = updatedCustomField.depends_on_custom_field_id;
    oldCustomField._translations = updatedCustomField._translations;
    oldCustomField.customerSelectedValue = updatedCustomField.customerSelectedValue;
    oldCustomField.is_multiple_select = updatedCustomField.is_multiple_select;
    oldCustomField.is_multiple_file = updatedCustomField.is_multiple_file;
    oldCustomField.is_read_only = updatedCustomField.is_read_only;
    oldCustomField.is_hidden = updatedCustomField.is_hidden;
    oldCustomField.hide_in_email = updatedCustomField.hide_in_email;
    oldCustomField.always_show = updatedCustomField.always_show;
  }
}

export class TranslationDescriptionModel {
  label: string;
  title: string;
  placeholder: string;
  locale: string;
}

export class CustomFieldOptionModel {
  id: number;
  label: string;
  custom_field_id: number;
  partner_id: number;
  position: number;
  is_multi_language: number;
  _translations?: {
    [key: string]: TranslationDescriptionModel
  };
  dependencies: number[] | string;
  title: string;
  image: string;
  number_default: number;
  number_min: number;
  number_max: number;
  is_fullscreen_image: number;
  option_value: string;
  showDependencies: boolean = Boolean(false);
}

export class CustomerCustomField {
  id: number;
  custom_field: CustomFieldModel;
  custom_field_id: number;
  customer_id: number;
  appointment_id?: number;
  value: any;
  file: CustomCustomFieldFileDbModel;
  option_value: string;
  uploadedFiles: { customerCustomFieldId: number, file: CustomCustomFieldFileDbModel }[] = [];
  hideCustomField: boolean = Boolean(false);
  selectedNumberCardOptions: {
    customFieldOption: CustomFieldOptionModel,
    customerCustomField: CustomerCustomField,
  }[] = [];
  is_past_value: number;
}
