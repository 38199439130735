import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {registerLocaleData} from '@angular/common';
import {PageNotFoundComponent} from './static-pages/page-not-found/page-not-found.component';
import {ForbiddenPageComponent} from './static-pages/forbidden-page/forbidden-page.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NoopHttpInterceptorService} from './shared/interceptor-services/noop-http-interceptor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgProgressModule} from '@ngx-progressbar/core';
import {NgProgressHttpModule} from '@ngx-progressbar/http';
import {NgProgressRouterModule} from '@ngx-progressbar/router';
import {ToastrModule} from 'ngx-toastr';
import {JwtModule} from '@auth0/angular-jwt';
import {LOCAL_STORAGE_CONSTANT} from './shared/constants/localstorage.constant';
import {WidgetSkeletonComponent} from './widget-skeleton/widget-skeleton.component';

// locals import required for currency pipe and date pipe
import localeDe from '@angular/common/locales/de';
import localeDeCH from '@angular/common/locales/de-CH';
import localeFr from '@angular/common/locales/fr';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeIt from '@angular/common/locales/it';
import localeItCh from '@angular/common/locales/it-CH';
import localeNl from '@angular/common/locales/nl';
import localFi from '@angular/common/locales/fi';

import {AuthorizeComponent} from './authorize/authorize.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {CalioDialogModule} from './calio-lib/calio-dialog/calio-dialog.module';
import {LogoutComponent} from './logout/logout.component';
import {NgxStripeModule} from 'ngx-stripe';
import {environment} from '../environments/environment';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {CalioDateAdapterService} from './shared/util-services/calio-date-adapter.service';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {FormsModule} from '@angular/forms';
import {SaferpayComponent} from './saferpay/saferpay.component';
import {CodeEditorModule} from '@ngstack/code-editor';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter(): string {
  const token: string = localStorage.getItem(LOCAL_STORAGE_CONSTANT.AUTH_TOKEN);
  return token;
}

export const PICK_FORMATS = {
  parse: {
    dateInput: {
      month: 'short',
      year: 'numeric',
      day: 'numeric'
    }
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};

// required for currency pipe and date pipe
registerLocaleData(localeDe, 'de');
registerLocaleData(localeDeCH, 'ch');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeFrCH, 'fr_CH');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeItCh, 'it_CH');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localFi, 'fi');

@NgModule({
  imports: [
    CodeEditorModule.forRoot(),
    BrowserModule.withServerTransition({appId: 'calio-dashboard'}),
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      newestOnTop: true,
      iconClasses: {
        error: 'toast-error',
        success: 'toast-success'
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    MatProgressSpinnerModule,
    CalioDialogModule,
    NgxStripeModule.forRoot(environment.stripeApiKey),
    MatCheckboxModule,
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ForbiddenPageComponent,
    WidgetSkeletonComponent,
    AuthorizeComponent,
    LogoutComponent,
    SaferpayComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoopHttpInterceptorService,
      multi: true,
    },
    {
      provide: 'LOCALSTORAGE', useFactory: getLocalStorage
    },
    {
      provide: LOCALE_ID, useValue: 'de-ch'
    },
    {
      provide: DateAdapter, useClass: CalioDateAdapterService
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: PICK_FORMATS
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}

export function getLocalStorage() {
  return (typeof window !== 'undefined') ? window.localStorage : null;
}
