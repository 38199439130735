import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {Auth0Service} from '../feature-services/auth-0.service';
import {AlertToastrService} from '../util-services/alert-toastr.service';
import {APP_CONSTANTS} from '../../shared/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class NoopHttpInterceptorService {
  appConstant = APP_CONSTANTS;
  whiteListUrls: string[] = [];

  constructor(
    private auth: Auth0Service,
    private alertToastrService: AlertToastrService
  ) {
    this.appConstant.LANGUAGES.forEach(language => {
      this.whiteListUrls.push(
        `./assets/i18n/${language.locale}.json`
      );
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // LoggerService.log('Inside NoopHttpInterceptorService: intercept ', req.url);

    if (this.whiteListUrls.indexOf(req.url) > -1) {
      return next.handle(req);
    }

    const headers: {
      [name: string]: string | string[];
    } = {};

    return this.auth.getTokenSilently$().pipe(
      mergeMap(token => {
        // LoggerService.log('Silent token inside interceptor is ', token);
        let tokenReq = req;
        // TODO need to check one more condition. Public page condition
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
          /*const partner: PartnerDbModel = this.partnerService.getPartnerFromLocalStorage();
          if (partner && partner.uuid) {
            // LoggerService.log('partner.uuid ', partner.uuid);
            headers['X-Requested-For'] = partner.uuid;
          }*/
          tokenReq = req.clone({
            setHeaders: headers
          });
        }

        return next.handle(tokenReq).pipe(
          map(event => {
            if (event instanceof HttpResponse) {
              const body: any = event.body;
              // LoggerService.log('body ', body);
              if (body && body.errors && body.errors[0]) {
                throw new HttpErrorResponse({
                  error: new Error(body.errors[0].message),
                  statusText: body.errors[0].message,
                  status: body.errors[0].code
                });
              } else if (body && body.errors) {
                if (body.errors.username && body.errors.username.unique) {
                  throw new HttpErrorResponse({
                    error: new Error(body.errors.username.unique),
                    statusText: body.errors.username.unique,
                    status: 400
                  });
                } else if (body.errors.email && body.errors.email.unique) {
                  throw new HttpErrorResponse({
                    error: new Error('Email is already registered.'),
                    statusText: 'Email is already registered.',
                    status: 400
                  });
                } else if (body.errors.code === 'malware') {
                  throw new HttpErrorResponse({
                    error: new Error(body.errors.message),
                    statusText: body.errors.code,
                    status: 400
                  });
                } else if (body.errors.code === 'file-size') {
                  throw new HttpErrorResponse({
                    error: new Error(body.errors.message),
                    statusText: body.errors.code,
                    status: 400
                  });
                } else if (body.errors) {
                  throw new HttpErrorResponse({
                    error: body.errors,
                    statusText: 'Something went wrong.',
                    status: 400
                  });
                }
              } else if (body && body.error) {
                if (body.message) {
                  throw new HttpErrorResponse({
                    error: new Error(body.message),
                    statusText: body.message,
                    status: body.status
                  });
                }
              } else {
                return event;
              }
            }
          }),
          catchError(
            (error: HttpErrorResponse) => {
              // LoggerService.log('error is ', error);
              if (error.status === 401) {
                this.auth.login();
              }
              if (error.status === 403) {
                this.alertToastrService.showError(`general.403_error_message`);
              }
              // LoggerService.log('interceptor error', JSON.stringify(error));
              throw error;
            })
        );
      }),
      catchError(err => throwError(err))
    );
  }
}
