import { Component, OnInit } from '@angular/core';
import {Auth0Service} from "../shared/feature-services/auth-0.service";

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
  styleUrls: ['./authorize.component.scss']
})
export class AuthorizeComponent implements OnInit {

  constructor(
    private auth0Service: Auth0Service,
  ) { }

  ngOnInit(): void {
  }

  logout() {
    this.auth0Service.logout();
  }

}
