export class StoreDbModel {
  id: number;
  uuid: string;
  name: string;
  email: string;
  phone: string;
  street: string;
  zip: number;
  city: string;
  lng: number;
  lat: number;
  is_online: number;
  place: string;
  position: number;
  is_multi_language: number;
  only_accessible_through_deeplink: number;
  _translations: {
    [key: string]: StoreDbModel;
  };
  country_id: number;
  logo: string;
}

export class StoreTranslationForm {
  is_multi_language: boolean;
  formControl_default: StoreMultiTranslationForm;
  id: number;
  uuid: string;
  street: string;
  zip: number;
  city: string;
  lng: number;
  lat: number;
  is_online: number;
  place: string;
  email: string;
  phone: string;
  country_id: number;
  logo: string;
  only_accessible_through_deeplink: number | boolean;
}

export class StoreMultiTranslationForm {
  name: string;
}
