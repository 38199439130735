import {Component, NgZone, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from './shared/util-services/local-storage.service';
import {PartnerService} from './shared/feature-services/partner.service';
import {NavigationEnd, Router} from '@angular/router';
import {CalioEventsService} from './shared/util-services/calio-events.service';
import {environment} from '../environments/environment';
import {LoggerService} from './shared/util-services/logger.service';
import {WorkerDbModel} from './shared/db-models/worker-db.model';
import {WorkerService} from './shared/feature-services/worker.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Auth0Service} from './shared/feature-services/auth-0.service';
import {CalioRouterService} from './shared/util-services/calio-router.service';
import {PartnerDbModel} from './shared/db-models/partner-db.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public barLabel = 'Password strength:';
  options = {
    min: 0,
    max: 100,
    ease: 'easeInCubic',
    speed: 200,
    trickleSpeed: 300,
    meteor: true,
    spinner: true,
    color: '#68d6f0',
    thick: true
  };
  lang: string;
  isProd: boolean;
  googleTagManagerCode: string;
  isIeorEdgeBrowser = false;
  showProdErrorMessage = false;
  freshDeskLoaded = false;
  loggedInWorker: WorkerDbModel;
  partner: PartnerDbModel;

  constructor(
    public translate: TranslateService,
    public localStorageService: LocalStorageService,
    public partnerService: PartnerService,
    private router: Router,
    private calioEventsService: CalioEventsService,
    private workerService: WorkerService,
    private auth0Service: Auth0Service,
    private ngZone: NgZone,
    public calioRouterService: CalioRouterService,
  ) {
    this.isProd = environment.production;
    this.googleTagManagerCode = environment.googleTagManagerCode;
    this.partnerService.setLangInLocalStorage(null, null);
  }

  ngOnInit(): void {
    this.loggedInWorker = this.workerService.getLoggedInWorkerFromLocalStorage();
    this.partner = this.partnerService.getPartnerFromLocalStorage();

    this.auth0Service.userIsLoggedInEvent.subscribe({
      next: (result: any) => {
        this.loggedInWorker = this.workerService.getLoggedInWorkerFromLocalStorage();
      }
    });

    this.auth0Service.isAuthenticated$.subscribe({
      next: (loggedIn: boolean) => {
        if (loggedIn) {
          this.loggedInWorker = this.workerService.getLoggedInWorkerFromLocalStorage();
          this.loadFreshDeskWidget();
        }
      }
    });

    this.appendGaTrackingCode();

    this.router.events.pipe().subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/app/calendar')) {
          this.calioEventsService.hideHeaderEvent.emit(true);
        } else {
          this.calioEventsService.hideHeaderEvent.emit(false);
        }
      }
    });

    if (environment.hostName === 'https://my.calenso.com' && (window.location.hostname === 'localhost' || window.location.hostname === 'calio.calenso.com')) {
      this.showProdErrorMessage = true;
    }
  }

  private appendGaTrackingCode() {
    try {
      const script = document.createElement('script');
      script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','` + this.googleTagManagerCode + `');`;
      document.head.appendChild(script);
    } catch (ex) {
      console.error('Error appending Google Tag Manager');
      console.error(ex);
    }
  }

  onFreshdeskLoad(): void {
    LoggerService.log('Inside onFreshdeskLoad()');
    this.ngZone.run(() => {
      this.initializeFreshdeskAPI();
    });
  }

  initializeFreshdeskAPI(): void {
    // LoggerService.log('Inside initializeFreshdeskAPI()');
    const script = document.createElement('script');
    script.innerHTML = `
        FreshworksWidget('prefill', 'ticketForm', {
        name: '${this.loggedInWorker ? this.loggedInWorker.resource_name : undefined} [${this.loggedInWorker ? this.loggedInWorker.partner_id : undefined}]',
        email: '${this.loggedInWorker ? this.loggedInWorker.email : undefined}',
        type: 'Question',
        custom_fields: {
          cf_order_id: '${this.loggedInWorker ? this.loggedInWorker.partner_id : undefined}'
        }
      });
    `;
    document.body.appendChild(script);
  }

  resetResellerAdmin(): void {
    this.workerService.resetResellerAdmin().subscribe({
      next: (result: { success: boolean }) => {
        if (result && result.success) {
          this.auth0Service.logout();
        }
      },
      error: (error: HttpErrorResponse) => {
        LoggerService.error(error);
      }
    });
  }

  private loadFreshDeskWidget(): void {
    if (this.freshDeskLoaded) {
      return;
    }

    if (
      window.location.href.indexOf(`${environment.deployUrl}/appointments`) > -1 ||
      window.location.href.indexOf(`${environment.deployUrl}/events`) > -1 ||
      window.location.href.indexOf(`${environment.deployUrl}/survey`) > -1 ||
      window.location.href.indexOf(`${environment.deployUrl}/app/calendar-pro`) > -1
    ) {
      // LoggerService.log('Not loading freshdesk');
    } else {
      const script = document.createElement('script');
      script.innerHTML = `
        window.fwSettings = {
        'widget_id': 80000000638,
        'locale': 'de'
      };
      !function () {
        if ("function" != typeof window.FreshworksWidget) {
          var n = function () {
            n.q.push(arguments)
          };
          n.q = [], window.FreshworksWidget = n
        }
      }()
    `;


      const script1 = document.createElement('script');
      script1.type = 'text/javascript';
      script1.src = 'https://euc-widget.freshworks.com/widgets/80000000638.js';
      script1.async = true;
      script1.defer = true;
      script1.onload = this.onFreshdeskLoad.bind(this);
      document.body.appendChild(script);
      document.body.appendChild(script1);
      this.freshDeskLoaded = true;
    }
  }
}
