export const APP_CONSTANTS = {
  APPOINTMENT: 'APPOINTMENT',
  EVENT: 'EVENT',
  WEBHOOK: 'WEBHOOK',
  LEADTIME: 'LEADTIME',

  LIST: 'LIST',
  GRID: 'GRID',

  ADD: 'ADD',
  EDIT: 'EDIT',

  AVAILABILITIES_FORM: 'AVAILABILITIES_FORM',
  EXCEPTIONAL_AVAILABILITIES_FORM: 'EXCEPTIONAL_AVAILABILITIES_FORM',

  APPOINTMENT_SERVICE_NEW_FORM_TYPE: 'APPOINTMENT_SERVICE_NEW_FORM_TYPE',
  APPOINTMENT_SERVICE_CATEGORY_FORM_TYPE:
    'APPOINTMENT_SERVICE_CATEGORY_FORM_TYPE',
  APPOINTMENT_SERVICE_EDIT_FORM_TYPE: 'APPOINTMENT_SERVICE_EDIT_FORM_TYPE',

  APPOINTMENT_SERVICE_CATEGORY_NEW_FORM_TYPE:
    'APPOINTMENT_SERVICE_CATEGORY_NEW_FORM_TYPE',
  APPOINTMENT_SERVICE_CATEGORY_EDIT_FORM_TYPE:
    'APPOINTMENT_SERVICE_CATEGORY_EDIT_FORM_TYPE',

  CATEGORY_NEW_FORM_TYPE: 'CATEGORY_NEW_FORM_TYPE',
  CATEGORY_EDIT_FORM_TYPE: 'CATEGORY_EDIT_FORM_TYPE',

  CUSTOMER_TYPE_NEW_FORM_TYPE: 'CUSTOMER_TYPE_NEW_FORM_TYPE',
  CUSTOMER_TYPE_EDIT_FORM_TYPE: 'CUSTOMER_TYPE_EDIT_FORM_TYPE',

  STORE_NEW_FORM_TYPE: 'STORE_NEW_FORM_TYPE',
  STORE_EDIT_FORM_TYPE: 'STORE_EDIT_FORM_TYPE',

  SMART_WIDGET_NEW_FORM_TYPE: 'SMART_WIDGET_NEW_FORM_TYPE',
  SMART_WIDGET_EDIT_FORM_TYPE: 'SMART_WIDGET_EDIT_FORM_TYPE',
  SMART_WIDGET_SURVEY: 'SMART_WIDGET_SURVEY',
  SMART_WIDGET_BOOKING: 'SMART_WIDGET_BOOKING',
  SMART_WIDGET_LEAD_GENERATOR: 'SMART_WIDGET_LEAD_GENERATOR',

  APPOINTMENT_GROUP_NEW_FORM_TYPE: 'APPOINTMENT_GROUP_NEW_FORM_TYPE',
  APPOINTMENT_GROUP_EDIT_FORM_TYPE: 'APPOINTMENT_GROUP_EDIT_FORM_TYPE',

  TAX_NEW_FORM_TYPE: 'TAX_NEW_FORM_TYPE',
  TAX_EDIT_FORM_TYPE: 'TAX_EDIT_FORM_TYPE',

  DEAL_NEW_FORM_TYPE: 'DEAL_NEW_FORM_TYPE',
  DEAL_EDIT_FORM_TYPE: 'DEAL_EDIT_FORM_TYPE',

  DEAL_AMOUNT_SCREEN: 'DEAL_AMOUNT_SCREEN',
  DEAL_DURATION_SCREEN: 'DEAL_DURATION_SCREEN',
  DEAL_COUPON_SCREEN: 'DEAL_COUPON_SCREEN',

  STORE_ONLINE: 'STORE_ONLINE',
  STORE_OFFLINE: 'STORE_OFFLINE',

  FEATURE_PROPOSAL_NEW_FORM_TYPE: 'FEATURE_PROPOSAL_NEW_FORM_TYPE',
  FEATURE_PROPOSAL_EDIT_FORM_TYPE: 'FEATURE_PROPOSAL_EDIT_FORM_TYPE',
  FEATURE_PROPOSAL_NEW_VOTE_FORM_TYPE: 'FEATURE_PROPOSAL_NEW_VOTE_FORM_TYPE',
  FEATURE_PROPOSAL_NEW_COMMENT_FORM_TYPE:
    'FEATURE_PROPOSAL_NEW_COMMENT_FORM_TYPE',
  FEATURE_PROPOSAL_DONE_STATUS: 'FEATURE_PROPOSAL_DONE_STATUS',
  FEATURE_PROPOSAL_OPEN_STATUS: 'FEATURE_PROPOSAL_OPEN_STATUS',

  QUESTION_FIELD_NEW_FORM_TYPE: 'QUESTION_FIELD_NEW_FORM_TYPE',
  QUESTION_FIELD_EDIT_FORM_TYPE: 'QUESTION_FIELD_EDIT_FORM_TYPE',
  QUESTION_FIELD_MULTILANGUAGE_FORM_TYPE:
    'QUESTION_FIELD_MULTILANGUAGE_FORM_TYPE',

  INTEGRATION_IFRAME: 'INTEGRATION_IFRAME',
  INTEGRATION_WEBCOMPONENT: 'INTEGRATION_WEBCOMPONENT',
  INTEGRATION_EMAIL_SIGNATURE: 'INTEGRATION_EMAIL_SIGNATURE',

  RESELLER_INTEGRATION_WEBCOMPONENT: 'RESELLER_INTEGRATION_WEBCOMPONENT',
  RESELLER_INTEGRATION_IFRAME: 'RESELLER_INTEGRATION_IFRAME',
  RESELLER_INTEGRATION_URL: 'RESELLER_INTEGRATION_URL',

  EMPLOYEE_NEW_FORM_TYPE: 'EMPLOYEE_NEW_FORM_TYPE',
  RESOURCE_NEW_FORM_TYPE: 'RESOURCE_NEW_FORM_TYPE',
  EMPLOYEE_STORE_FORM_TYPE: 'EMPLOYEE_STORE_FORM_TYPE',
  EMPLOYEE_EDIT_FORM_TYPE: 'EMPLOYEE_EDIT_FORM_TYPE',
  RESOURCE_EDIT_FORM_TYPE: 'RESOURCE_EDIT_FORM_TYPE',
  EMPLOYEE_PASSWORD_FORM_TYPE: 'EMPLOYEE_PASSWORD_FORM_TYPE',
  AVAILABILITIES_FIRST_SCREEN: 'AVAILABILITIES_FIRST_SCREEN',
  AVAILABILITIES_RECURRING_SCREEN: 'AVAILABILITIES_RECURRING_SCREEN',
  AVAILABILITIES_ABSENCES_SCREEN: 'AVAILABILITIES_ABSENCES_SCREEN',
  CUSTOM_ABSENCES_SCREEN: 'CUSTOM_ABSENCES_SCREEN',
  IMPORT_HOLIDAYS_ABSENCES_SCREEN: 'IMPORT_HOLIDAYS_ABSENCES_SCREEN',
  EVENTS_BASIC_SCREEN: 'EVENTS_BASIC_SCREEN',
  EVENTS_NOTIFICATION_SCREEN: 'EVENTS_NOTIFICATION_SCREEN',
  EVENTS_CANCELLATION_SCREEN: 'EVENTS_CANCELLATION_SCREEN',
  URL_REGEX: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,

  DAYS: [
    {
      sn_text: 'mon',
      ln_text: 'monday',
      value: 1,
      calValue: 1,
    },
    {
      sn_text: 'tue',
      ln_text: 'tuesday',
      value: 2,
      calValue: 2,
    },
    {
      sn_text: 'wed',
      ln_text: 'wednesday',
      value: 3,
      calValue: 3,
    },
    {
      sn_text: 'thu',
      ln_text: 'thursday',
      value: 4,
      calValue: 4,
    },
    {
      sn_text: 'fri',
      ln_text: 'friday',
      value: 5,
      calValue: 5,
    },
    {
      sn_text: 'sat',
      ln_text: 'saturday',
      value: 6,
      calValue: 6,
    },
    {
      sn_text: 'sun',
      ln_text: 'sunday',
      value: 7,
      calValue: 0,
    },
  ],
  PUBLIC_HOLIDAY_TYPES: {
    SWISS: [
      {
        text: 'school_holidays_text_1',
        value: 0,
      },
      {
        text: 'school_holidays_text_2',
        value: 3,
      },
      {
        text: 'school_holidays_text_3',
        value: 4,
      },
      {
        text: 'school_holidays_text_4',
        value: 5,
      },
    ],
    OTHER_LANGS: [
      {
        text: 'other_school_holidays_text_2',
        value: 3,
      },
      {
        text: 'other_school_holidays_text_3',
        value: 4,
      },
      {
        text: 'other_school_holidays_text_4',
        value: 5,
      },
    ],
  },
  LANGUAGES: [
    {
      text: 'german',
      value: 'de',
      secondary_text: 'DE',
      locale: 'de_CH',
      flagIcon: '/assets/images/flags/if_Flag_of_Switzerland_-_Circle.png'
    },
    {
      text: 'french',
      value: 'fr',
      secondary_text: 'FR',
      locale: 'fr_CH',
      flagIcon: '/assets/images/flags/if_Flag_of_France_-_Circle.png'
    },
    {
      text: 'italian',
      value: 'it',
      secondary_text: 'IT',
      locale: 'it_CH',
      flagIcon: '/assets/images/flags/if_Flag_of_Italy_-_Circle.png'
    },
    {
      text: 'english',
      value: 'en',
      secondary_text: 'EN',
      locale: 'en_US',
      flagIcon: '/assets/images/flags/if_Flag_of_United_States_-_Circle.png'
    },
    {
      text: 'dutch',
      value: 'nl',
      secondary_text: 'NL',
      locale: 'nl_NL',
      flagIcon: '/assets/images/flags/dutch.png'
    },
    {
      text: 'finnish',
      value: 'fi',
      secondary_text: 'FI',
      locale: 'fi_FI',
      flagIcon: '/assets/images/flags/finnish.png'
    }
  ],
  IMAGE_UPLOAD_TYPE: {
    PARTNER_LOGO_UPLOAD: 'PARTNER_LOGO_UPLOAD',
    WORKER_AVATAR_UPLOAD: 'WORKER_AVARTAR_UPLOAD',
    EVENT_IMAGE_UPLOAD: 'EVENT_IMAGE_UPLOAD',
    PARTNER_LANDINGPAGE_LOGO_UPLOAD: 'PARTNER_LANDINGPAGE_LOGO_UPLOAD',
    PARTNER_FAVICON_LOGO_UPLOAD: 'PARTNER_FAVICON_LOGO_UPLOAD',
    PARTNER_EMAIL_LOGO_UPLOAD: 'PARTNER_EMAIL_LOGO_UPLOAD',
    STORE_LOGO_UPLOAD: 'STORE_LOGO_UPLOAD'
  },
  INTEGRATION_FIRST_SCREEN: 'INTEGRATION_FIRST_SCREEN',
  INTEGRATION_BOOKING_MICROSITE_SCREEN: 'INTEGRATION_BOOKING_MICROSITE_SCREEN',
  INTEGRATION_WEBSITE_SCREEN: 'INTEGRATION_WEBSITE_SCREEN',

  SUBSCRIPTION_FREE_TYPE: {
    id: 1,
    name: 'free',
    desc: 'Calenso Free',
    maxNumberOfBookings: 100,
  },
  SUBSCRIPTION_LITE_TYPE: {
    id: 2,
    name: 'lite',
    desc: 'Calenso Lite',
  },
  SUBSCRIPTION_PREMIUM_TYPE: {
    id: 3,
    name: 'premium',
    desc: 'Calenso Premium',
  },
  SUBSCRIPTION_FLATRATE_TYPE: {
    id: 5,
    name: 'flatrate',
    desc: 'Calenso Flatrate',
  },
  SUBSCRIPTION_WHITELABEL_TYPE: {
    id: 100,
    name: 'whitelable',
    desc: 'Calenso Whitelable',
  },
  SUBSCRIPTION_ENTERPRISE_TYPE: {
    id: 101,
    name: 'enterprise',
    desc: 'Calenso Emterprise',
  },
  PROBLEM_NOTIFICATION: 'problem',
  ANNOUNCEMENT_NOTIFICATION: 'announcement',
  WARNING_NOTIFICATION: 'warning',
  INFORMATION_NOTIFICATION: 'information',

  CASH_ON_SITE_SCREEN: 'CASH_ON_SITE_SCREEN',
  CREDIT_CARD_SCREEN: 'CREDIT_CARD_SCREEN',

  PERCENTAGE_SCREEN: 'PERCENTAGE_SCREEN',
  FIXED_AMOUNT_SCREEN: 'FIXED_AMOUNT_SCREEN',

  REPEATING_DAYS: 'REPEATING_DAYS',
  REPEATING_DATES: 'REPEATING_DATES',

  CUSTOM_FIELD_SELECT: 'select',
  CUSTOM_FIELD_TEXT: 'text',
  CUSTOM_FIELD_TEXT_AREA: 'textarea',
  CUSTOM_FIELD_CHECKBOX: 'checkbox',
  CUSTOM_FIELD_FILE: 'file',
  CUSTOM_FIELD_RADIO: 'radio',
  CUSTOM_FIELD_DATE: 'date',
  CUSTOM_FIELD_INFORMATION: 'information',
  CUSTOM_FIELD_NUMBER_SELECT_CARD: 'number-select',
  CUSTOM_FIELD_IMAGE_SELECT_CARD: 'image-select',
  CUSTOM_FIELD_SELECT_CARD: 'card-select',
  DE_SCREEN: 'DE',
  EN_SCREEN: 'EN',
  IT_SCREEN: 'IT',
  FR_SCREEN: 'FR',
  NL_SCREEN: 'NL',
  SUPPORTED_DATE_FORMATS: [
    'dd.MM.yyyy',
    'MM.dd.yyyy',
    'yyyy.MM.dd',
    'yyyy-MM-dd',
  ],
  SUPPORTED_COLOR_FORMATS: [
    {
      display: 'neutral_color',
      value: 'neutral',
    },
    {
      display: 'red_color',
      value: 'red',
    },
    {
      display: 'yellow_color',
      value: 'yellow',
    },
    {
      display: 'blue_color',
      value: 'blue',
    },
  ],
};

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const YEAR_FORMAT = {
  parse: {
    dateInput: 'YYYY.MM.DD',
  },
  display: {
    dateInput: 'YYYY.MM.DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const WIDGET_EXPAND_OPTIONS: any[] = [
  {
    display: 'widget_expand_appointment_service_categories_option_1',
    value: 0,
  },
  {
    display: 'widget_expand_appointment_service_categories_option_2',
    value: 1,
  },
  {
    display: 'widget_expand_appointment_service_categories_option_3',
    value: 2,
  },
];

export const CANCELLATION_NOTIFICATION_TYPES: any[] = [
  {
    display: 'inform_the_partner',
    value: 1,
  },
  {
    display: 'worker_option',
    value: 2,
  },
  {
    display: 'custom_email_option',
    value: 3,
  },
  {
    display: 'inform_store',
    value: 4,
  },
];

export const NOTIFICATION_TYPES: any[] = [
  {
    display: 'email',
    value: 'email',
  },
  {
    display: 'sms',
    value: 'sms',
  },
  {
    display: 'both_sms_email',
    value: 'both',
  },
];

export const SINGLE_WORKER_NOTIFICATIONS: any[] = [
  {
    display: 'inform_the_partner',
    value: 0,
  },
  {
    display: 'inform_each_employee',
    value: 1,
  },
  {
    display: 'inform_resource_and_partner',
    value: 2,
  },
  {
    display: 'inform_store',
    value: 3,
  },
  {
    display: 'inform_store_and_resource',
    value: 4,
  },
  {
    display: 'inform_partner_and_store',
    value: 5,
  },
];

export const APPOINTMENT_CONFIRMATION_TYPES: any[] = [
  {
    display: 'inform_each_employee',
    value: 1,
  },
  {
    display: 'inform_the_partner',
    value: 0,
  },
];

export const COURTESY_FORM_TYPES: any[] = [
  {
    display: 'informal',
    value: 'Du',
  },
  {
    display: 'formal',
    value: 'Sie',
  },
];

export const HOUR_TYPES: any[] = [
  {
    display: '1 ',
    value: 1,
  },
  {
    display: '2 ',
    value: 2,
  },
  {
    display: '3 ',
    value: 3,
  },
  {
    display: '4 ',
    value: 4,
  },
  {
    display: '5 ',
    value: 5,
  },
  {
    display: '6 ',
    value: 6,
  },
  {
    display: '12 ',
    value: 12,
  },
  {
    display: '24 ',
    value: 24,
  },
  {
    display: '36 ',
    value: 36,
  },
  {
    display: '48 ',
    value: 48,
  },
];

export const BOOKING_INTERVAL_TYPES: any[] = [
  {
    display: '1 ',
    value: 1,
  },
  {
    display: '2 ',
    value: 2,
  },
  {
    display: '3 ',
    value: 3,
  },
  {
    display: '4 ',
    value: 4,
  },
  {
    display: '5 ',
    value: 5,
  },
  {
    display: '6 ',
    value: 6,
  },
  {
    display: '7 ',
    value: 7,
  },
  {
    display: '8 ',
    value: 8,
  },
  {
    display: '9 ',
    value: 9,
  },
  {
    display: '10 ',
    value: 10,
  },
  {
    display: '15 ',
    value: 15,
  },
  {
    display: '20 ',
    value: 20,
  },
  {
    display: '30 ',
    value: 30,
  },
  {
    display: '45 ',
    value: 45,
  },
  {
    display: '60 ',
    value: 60,
  },
  {
    display: '90 ',
    value: 90,
  },
  {
    display: '120 ',
    value: 120,
  },
];

export const CALENDAR_EVENT_REQUEST_TYPE: any = {
  EVENT_CREATED: 'EVENT_CREATED',
  EVENT_UPDATED: 'EVENT_UPDATED',
  EVENT_DELETED: 'EVENT_DELETED',
};

export const CALENDAR_EVENT_TYPE: any = {
  CALENSO: 'CALENSO',
  SOCIAL: 'SOCIAL',
  APPOINTMENT: 'APPOINTMENT',
  ABSENSE: 'ABSENSE',
};

export const CALENDAR_COLOR_CONSTANT: { key: string; value: string }[] = [
  {
    key: 'white',
    value: '#ffffff',
  },
  {
    key: 'black',
    value: '#000000',
  },
  {
    key: 'red',
    value: '#fad9d5',
  },
  {
    key: 'vacation',
    value:
      'background: repeating-linear-gradient(45deg, rgba(155, 155, 155, 0.5), rgba(155, 155, 155, 0.5) 8px, transparent 8px, transparent 16px);color: #666;',
  },
  {
    key: 'green',
    value: '#d2f6d5',
  },
  {
    key: 'blue',
    value: '#AACDFC',
  },
  {
    key: 'violett',
    value: '#B1B2FD',
  },
  {
    key: 'orange',
    value: '#ffeed6',
  },
  {
    key: 'yellow',
    value: '#ffee9a',
  },
  {
    key: 'google',
    value: '#9A9DA2',
  },
  {
    key: 'icloud',
    value: '#9A9DA2',
  },
  {
    key: 'exchange',
    value: '#9A9DA2',
  },
  {
    key: 'office365',
    value: '#9A9DA2',
  },
  {
    key: 'external',
    value: '#9A9DA2',
  },
];

export const DELETE_CONST_TYPE: { [key: string]: string } = {
  DEFAULT: 'DEFAULT',
  ICON: 'ICON',
  ICON_AND_LABEL: 'ICON_AND_LABEL',
  X_BUTTON: 'X_BUTTON',
  SELECTED_DELETE_BUTTON: 'SELECTED_DELETE_BUTTON',
  LABEL_BUTTON: 'LABEL_BUTTON',
  X_FA_ICON: 'X_FA_ICON',
};

export const EMPLOYEE_CONST_TYPE = {
  AVATAR: 'AVATAR',
  SELECT: 'SELECT',
  SELECT_AVATAR: 'SELECT_AVATAR',
  SMALL_MULTISELECT_AVATAR: 'SMALL_MULTISELECT_AVATAR',
};

export const FILE_CONST_TYPE = {
  PDF: 'PDF',
  EXCEL: 'EXCEL',
};

export const MEETING_TYPE = {
  ZOOM: 'zoom',
  CALENSO: 'calenso',
  PHONE: 'phone',
  STORE: 'store',
  CUSTOMER: 'customer',
  UNBLU: 'unblu',
  MICROSOFT_TEAMS: 'teams',
  GOOGLE_MEET: 'meet',
  GOTO_MEETING: 'gotomeeting',
  WEBEX: 'webex',
  WILL_CALL_YOU: 'wewillcallyou',
};

export const CALENDAR_TYPE = {
  OFFICE_365: 'office365',
  EXCHANGE: 'exchange',
  ICLOUD: 'icloud',
  GOOGLE: 'google',
  CALDAV: 'caldav',
};

export const EXPORT_API_TYPE = {
  EVENT_OVERVIEW: 'event_overview',
  EVENT_DETAIL: 'event_detail',
  SLOT_DETAIL: 'slot_detail',
};

export const CALENSO_RATE_TYPE = {
  FLATRATE: 'FLATRATE',
  WHITELABEL: 'WHITELABEL',
};

export const EMAIL_TEMPLATE_CONST = {
  de_CH: {
    finish: 'Freundliche Grüsse<br>[PARTNER_NAME]',
    footer:
      'Diese E-Mail wurde automatisch erzeugt. <a href="https://www.calenso.com" target="_blank">Calenso</a> ist ein Service der <a href="https://www.braincept.com" target="_blank">Braincept AG</a>.<br/><br/> <a href="https://www.facebook.com/calenso" target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-facebook-icon.png" width="20" height="20"/> </a> <a href="https://twitter.com/calenso_com" target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-twitter-icon.png" width="20" height="20"/> </a>',
    intro:
      'Du hast erfolgreich einen Termin bei der Firma <b>[PARTNER_NAME]</b> in [LOCATION] vereinbart. <br><br>Wir haben den Termin direkt dieser E-Mail angehängt, damit Du ihn bequem in deinen Kalender einfügen kannst!',
    salutation: 'Hallo [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  en_US: {
    finish: 'Kind regards<br>[PARTNER_NAME]',
    footer:
      'This e-mail was generated automatically. <a href="https://www.calenso.com" target="_blank">Calenso</a> is a service of <a href="https://www.braincept.com" target="_blank">Braincept AG</a>.<br/><br/> <a href="https://www.facebook.com/calenso" target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-facebook-icon.png" width="20" height="20"/> </a> <a href="https://twitter.com/calenso_com” target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-twitter-icon.png" width="20" height="20"/> </a>',
    intro:
      'You have successfully arranged an appointment with the company <b>[LOCATION]</b> in [ORT]. <br><br>We have attached the appointment directly to this e-mail so that you can easily add it to your calendar!',
    salutation: 'Dear [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  it_CH: {
    finish: 'Cordiali saluti<br>[PARTNER_NAME]',
    footer:
      'Questa email è stata generata automaticamente. <a href="https://www.calenso.com" target="_blank">Calenso</a> è un servizio di <a href="https://www.braincept.com" target="_blank">Braincept AG</a>.<br/> <br/><a href="https://www.facebook.com/calenso" target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-facebook-icon.png" width="20" height="20" /> </a><a href ="https://twitter.com/calenso_com" target=" _ blank "> <img src ="https://braincept.com/wp-content/uploads/2016/11/mail-twitter-icon.png" width="20" height = "20" /> </a>',
    intro:
      'hai fissato un appuntamento presso l’azienda <b>[PARTNER_NAME]</b> di [LOCATION]. <br><br>Abbiamo allegato l’appuntamento direttamente a questa e-mail per consentirti di inserirlo comodamente nel tuo calendario.',
    salutation: 'Salve [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  fr_CH: {
    finish: 'Meilleures salutations<br>[PARTNER_NAME]',
    footer:
      'Cet e-mail a été généré automatiquement. <a href="https://www.calenso.com" target="_blank">Calenso</a> est un service de <a href="https://www.braincept.com" target="_blank">Braincept AG</a>.<br/><br/> <a href="https://www.facebook.com/calenso" target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-facebook-icon.png" width="20" height="20"/> </a> <a href="https://twitter.com/calenso_com" target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-twitter-icon.png" width="20" height="20"/> </a>',
    intro:
      'Vous avez organisé avec succès plusieurs rendez-vous avec la société <b> [PARTNER_NAME] </b> dans [LOCATION]. <br><br>Nous avons joint les dates directement à cet e-mail, afin que vous puissiez facilement les ajouter dans votre calendrier!',
    salutation: 'Bonjour [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  nl_NL: {
    finish: 'Met vriendelijke groet<br>[PARTNER_NAME]',
    footer:
      'Deze e-mail werd automatisch gegenereerd. <a href="https://www.calenso.com" target="_blank">Calenso</a> is een dienst van <a href="https://www.braincept.com" target="_blank">Braincept AG</a>.<br/><br/> <a href="https://www.facebook.com/calenso" target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-facebook-icon.png" width="20" height="20"/> </a> <a href="https://twitter.com/calenso_com" target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-twitter-icon.png" width="20" height="20"/> </a>',
    intro:
      'U hebt met succes verschillende vergaderingen georganiseerd met het bedrijf <b> [PARTNER_NAME] </b> in [LOCATION]. <br><br>We hebben de data direct bij deze e-mail gevoegd, zodat u ze gemakkelijk in uw agenda kunt zetten!',
    salutation: 'Goeiedag, [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
  fi_FI: {
    finish: 'Ystävällisin terveisin<br>[PARTNER_NAME]',
    footer:
      'Deze e-mail werd automatisch gegenereerd. <a href="https://www.calenso.com" target="_blank">Calenso</a> is een dienst van <a href="https://www.braincept.com" target="_blank">Braincept AG</a>.<br/><br/> <a href="https://www.facebook.com/calenso" target="_blank"><img src="https://braincept. com/wp-content/uploads/2016/11/mail-facebook-icon.png" width="20" height="20"/> </a> <a href="https://twitter.com/calenso_com" target="_blank"><img src="https://braincept.com/wp-content/uploads/2016/11/mail-twitter-icon.png" width="20" height="20"/> </a>',
    intro:
      'U hebt met succes verschillende vergaderingen georganiseerd met het bedrijf <b> [PARTNER_NAME] </b> in [LOCATION]. <br><br>We hebben de data direct bij deze e-mail gevoegd, zodat u ze gemakkelijk in uw agenda kunt zetten!',
    salutation: 'Rakas, [CUSTOMER_PRENAME] [CUSTOMER_LASTNAME]',
  },
};

export const RESELLER_CONSTANTS = {
  PAYMENTS: 'PAYMENTS',
  REFERRALS: 'REFERRALS',
  INVITATIONS: 'INVITATIONS',
};

export const TEMPLATE_TYPES: any[] = [
  {
    display: 'email',
    value: 'email',
  },
  {
    display: 'sms',
    value: 'sms',
  },
  {
    display: 'general',
    value: 'general',
  },
];

export const RELEASE_NOTE_TYPES: { id: number; description: string }[] = [
  {
    id: 1,
    description: 'Bugfixing',
  },
  {
    id: 2,
    description: 'New Functions',
  },
  {
    id: 3,
    description: 'Hotfix',
  },
];

export const LANG_TYPES: {
  display: string;
  value: string;
  langIdentifer: string;
}[] = [
    {
      display: 'german',
      value: 'de',
      langIdentifer: 'de_CH',
    },
    {
      display: 'french',
      value: 'fr',
      langIdentifer: 'fr_CH',
    },
    {
      display: 'english',
      value: 'en',
      langIdentifer: 'en_US',
    },
    {
      display: 'italian',
      value: 'it',
      langIdentifer: 'it_CH',
    },
    {
      display: 'dutch',
      value: 'nl',
      langIdentifer: 'nl_NL',
    },
    {
      display: 'finnish',
      value: 'fi',
      langIdentifer: 'fi_FI',
    },
  ];

export const BOTTOM_TYPES: {
  display: string;
  value: string;
}[] = [
    {
      display: 'bottom_right',
      value: 'bottom-right',
    },
    {
      display: 'bottom_left',
      value: 'bottom-left',
    },
  ];

export const TRIGGER_TYPE = {
  IMMEDIATELY: 1,
};

export const CALIO_STANDARD_COLORS = {
  WHITE: '#FFFFFF',
  VIOLET: '#7676FF',
};

export const SMART_WIDGET_DEFAULT_DATA = {
  // type: 3,
  button_font_color: CALIO_STANDARD_COLORS.WHITE,
  header_font_color: CALIO_STANDARD_COLORS.WHITE,
  header_background_color: CALIO_STANDARD_COLORS.VIOLET,
  button_background_color: CALIO_STANDARD_COLORS.VIOLET,
  position: 'bottom-right',
  right_padding: 20,
  left_padding: 20,
  bottom_padding: 20,
  locale: 'de_CH',
  whitelisted_domains: 'dashboard.calenso.com,calenso.com',
  context: {
    worker_id: 0,
    appointment_ids: null,
    store_id: 2,
    date: '[TOMORROW]',
    is_internal: false,
  },
};

export const SALUTATION_TYPES: any[] = [
  {
    display: 'mr',
    value: 'm',
  },
  {
    display: 'ms',
    value: 'f',
  },
  {
    display: 'other',
    value: 'o',
  },
];

export const SMART_WIDGET_TYPES: { display: string; value: number }[] = [
  {
    display: 'lead',
    value: 1,
  },
  {
    display: 'calendar_fill',
    value: 4,
  },
  {
    display: 'booking',
    value: 3,
  },
  {
    display: 'survey',
    value: 2,
  },
];

export const SMART_WIDGET_CUSTOMER_TYPES: {
  display: string;
  value: string;
  type: string;
}[] = [
    { display: 'salutation', value: 'salutation', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'prename', value: 'prename', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'lastname', value: 'lastname', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'birthday', value: 'birthday', type: 'CUSTOMER_FORM_FIELD' },
    {
      display: 'meeting_types',
      value: 'meeting_types',
      type: 'CUSTOMER_FORM_FIELD',
    },
    {
      display: 'notification_preferences',
      value: 'notification_preferences',
      type: 'CUSTOMER_FORM_FIELD',
    },
    { display: 'email', value: 'email', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'phone', value: 'phone', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'address', value: 'address', type: 'CUSTOMER_FORM_FIELD' },
    { display: 'comment', value: 'comment', type: 'CUSTOMER_FORM_FIELD' },
  ];

export const SMART_WIDGET_ALL_FIELDS: {
  key: string;
  isRequired?: boolean;
  isMultiLang?: boolean;
  isExpert?: boolean;
}[] = [
    // {key: 'type'},
    { key: 'button_font_color' },
    { key: 'header_font_color' },
    { key: 'header_background_color' },
    { key: 'button_background_color' },
    { key: 'position' },
    { key: 'right_padding' },
    { key: 'left_padding' },
    { key: 'bottom_padding' },
    { key: 'locale' },
    { key: 'whitelisted_domains' },
    { key: 'context' },
    { key: 'title', isMultiLang: true },
    { key: 'description', isMultiLang: true },
    { key: 'trigger_message', isMultiLang: true },
    { key: 'lead_intro_text', isMultiLang: true },
    { key: 'lead_outro_text', isMultiLang: true },
    { key: 'start_text', isMultiLang: true },
    { key: 'end_text', isMultiLang: true },
    { key: 'show_worker_avatar' },
    { key: 'worker_uuid' },
    { key: 'worker_ids' },
    { key: 'ask_for_customer_notification_preference' },
    { key: 'show_questions_before_booking_process' },
    { key: 'show_meeting_type_picker' },
  ];

export const SMART_WIDGET_LEAD_FORM_FIELDS: {
  key: string;
  isRequired?: boolean;
  isMultiLang?: boolean;
  isExpert?: boolean;
}[] = [
    // {key: 'type', isExpert: true},
    { key: 'button_font_color', isExpert: true },
    { key: 'header_font_color', isExpert: true },
    { key: 'header_background_color', isExpert: true },
    { key: 'button_background_color', isExpert: true },
    { key: 'position', isExpert: true },
    { key: 'right_padding', isExpert: true },
    { key: 'left_padding', isExpert: true },
    { key: 'bottom_padding', isExpert: true },
    { key: 'locale', isExpert: true },
    { key: 'whitelisted_domains', isExpert: true },
    { key: 'context', isExpert: true },
    { key: 'title', isMultiLang: true },
    { key: 'description', isMultiLang: true },
    { key: 'trigger_message', isMultiLang: true },
    { key: 'lead_intro_text', isMultiLang: true },
    { key: 'lead_outro_text', isMultiLang: true },
    { key: 'show_worker_avatar' },
    { key: 'worker_uuid' },
    { key: 'worker_ids' },
    { key: 'ask_for_customer_notification_preference' },
  ];

export const SMART_WIDGET_APPOINTMENT_FORM_FIELDS: {
  key: string;
  isRequired?: boolean;
  isMultiLang?: boolean;
  isExpert?: boolean;
}[] = [
    // {key: 'type'},
    { key: 'button_font_color', isExpert: true },
    { key: 'header_font_color', isExpert: true },
    { key: 'header_background_color', isExpert: true },
    { key: 'button_background_color', isExpert: true },
    { key: 'position', isExpert: true },
    { key: 'right_padding', isExpert: true },
    { key: 'left_padding', isExpert: true },
    { key: 'bottom_padding', isExpert: true },
    { key: 'locale', isExpert: true },
    { key: 'whitelisted_domains', isExpert: true },
    { key: 'context', isExpert: true },
    { key: 'title', isMultiLang: true },
    { key: 'description', isMultiLang: true },
    { key: 'trigger_message', isMultiLang: true },
    { key: 'lead_intro_text', isMultiLang: true },
    { key: 'lead_outro_text', isMultiLang: true },
    { key: 'ask_for_customer_notification_preference' },
    { key: 'show_questions_before_booking_process' },
    { key: 'show_meeting_type_picker' },
  ];

export const SMART_WIDGET_SURVEY_FORM_FIELDS: {
  key: string;
  isRequired?: boolean;
  isMultiLang?: boolean;
  isExpert?: boolean;
}[] = [
    // {key: 'type'},
    { key: 'button_font_color', isExpert: true },
    { key: 'header_font_color', isExpert: true },
    { key: 'header_background_color', isExpert: true },
    { key: 'button_background_color', isExpert: true },
    { key: 'position', isExpert: true },
    { key: 'right_padding', isExpert: true },
    { key: 'left_padding', isExpert: true },
    { key: 'bottom_padding', isExpert: true },
    { key: 'locale', isExpert: true },
    { key: 'whitelisted_domains', isExpert: true },
    { key: 'context', isExpert: true },
    { key: 'start_text', isMultiLang: true },
    { key: 'end_text', isMultiLang: true },
  ];

export const SMART_ACTION_INTERVALS: { display: string; value: string }[] = [
  {
    display: 'daily',
    value: 'daily',
  },
  {
    display: 'weekly',
    value: 'weekly',
  },
  {
    display: 'monthly',
    value: 'monthly',
  },
  {
    display: 'yearly',
    value: 'yearly',
  },
];

export const BOOKING_QUESTION_REORDERING = {
  SW_BOOKING_QUESTIONS_STEP: 'SW_BOOKING_QUESTIONS_STEP',
  SW_SURVEY_STEP_BOOKING_QUESTIONS: 'SW_SURVEY_STEP_BOOKING_QUESTIONS',
};

export const SMS_PROVIDERS: {
  id: number;
  value: string;
  slug: string;
  prefRegion: string;
}[] = [
    {
      id: 140,
      value: `SMSAPI`,
      slug: 'smsapi',
      prefRegion: 'EU (PL)',
    },
    {
      id: 195,
      value: `Sendinblue`,
      slug: 'sendinblue',
      prefRegion: 'EU (DE)',
    },
  ];

export const ENTITY_TYPE: { id: number; value: string }[] = [
  {
    id: 1,
    value: `resource`,
  },
  {
    id: 2,
    value: `appointment`,
  },
];

export const FEATURE_PROPOSAL_URL =
  'https://calenso.feedbear.com/boards/feature-proposals';

export const VERSION_URL = 'https://calenso.feedbear.com/updates';

export const SAFERPAY_API_URL: { text: string; value: string }[] = [
  {
    text: `TEST (https://test.saferpay.com/api)`,
    value: `https://test.saferpay.com/api`,
  },
  {
    text: `PROD (https://www.saferpay.com/api)`,
    value: `https://www.saferpay.com/api`,
  },
];

export const UNBLU_AUTH_TYPE: { value: string; text: string }[] = [
  {
    text: `external_calendar_settings_component.unblu_basic_auth`,
    value: `basic`,
  },
  {
    text: `external_calendar_settings_component.unblu_OAuth2`,
    value: `oauth`,
  },
];

export const PARTNER_SETTING_TYPES: { [key: string]: string } = {
  STRING: 'string',
  INT: 'int',
  BOOLEAN: 'boolean'
};

export const DEFAULT_SELECTED_STORE_WORKERS_LIMIT_FOR_CALENDAR = 5;

export const SMART_ACTION_EXPORT_DURATION: { label: string; value: number }[] = [{
  label: 'smart_actions_component.all',
  value: 0
}, {
  label: 'smart_actions_component.this_year',
  value: 1
}, {
  label: 'smart_actions_component.last_year',
  value: 2
}, {
  label: 'smart_actions_component.last_two_year',
  value: 3
}];
