import {EventEmitter, Injectable} from '@angular/core';
import {HttpClientService} from '../util-services/http-client.service';
import {PartnerValidationModel} from '../client-models/partner-validation-data.model';
import {Observable, of} from 'rxjs';
import {PartnerDbModel, PartnerTranslationForm} from '../db-models/partner-db.model';
import {LocalStorageService} from '../util-services/local-storage.service';
import {LOCAL_STORAGE_CONSTANT} from '../constants/localstorage.constant';
import {CryptoService} from '../util-services/crypto.service';
import {switchMap} from 'rxjs/operators';
import {HelperService} from '../util-services/helper.service';
import {TranslateService} from '@ngx-translate/core';
import {SubscriptionModel} from '../db-models/subscribe.model';
import {LoggerService} from '../util-services/logger.service';
import {HttpParams} from '@angular/common/http';
import {WorkerDbModel} from '../db-models/worker-db.model';
import {ConfigValidationModel} from '../client-models/config-validation.model';
import {DigitizationSuiteFormModel} from '../client-models/request-digitalization-suite.model';
import {APP_CONSTANTS} from '../constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  partnerChangeEvent: EventEmitter<PartnerDbModel> = new EventEmitter();
  partnerLoadEvent: EventEmitter<PartnerDbModel> = new EventEmitter();

  constructor(
    private httpClientService: HttpClientService,
    private localStorageService: LocalStorageService,
    private cryptoService: CryptoService,
    public helperService: HelperService,
    public translate: TranslateService,
  ) {
  }

  public updatePartnerRoles(partnerRoleModel: { partner_id: number, role_ids: number[] }): Observable<any> {
    return this.httpClientService.post('partners/roles', partnerRoleModel, {});
  }

  public getPartners(): Observable<any> {
    return this.httpClientService.get('partners', {});
  }

  public searchPartners(query: string): Observable<PartnerDbModel[]> {
    const body: { query: string } = { query };
    return this.httpClientService.post(`partners/search`, body, {});
  }

  public getPartnerDashboard(limit: number, offset: number): Observable<{
    partners: PartnerDbModel[],
    total_count: number,
  }> {
    const params = new HttpParams()
      .set('limit', limit + '')
      .set('offset', offset + '');
    return this.httpClientService.get(`partners/dashboard`, { params });
  }

  getPartnersByName(name: string): Observable<any> {
    const body: {
      name: {
        value: string,
        operator: string
      };
    } = {
      name: {
        value: name,
        operator: 'like'
      }
    };

    return this.httpClientService.post('partners/filter', body, {});
  }

  public validatePartner(partnerValidationModel: PartnerValidationModel): Observable<{ isPartnerValid: PartnerValidationModel }> {
    return this.httpClientService.post('partners/validation', partnerValidationModel, {});
  }

  public createPartner(partnerDbModel: PartnerDbModel) {
    return this.httpClientService.post('partners', partnerDbModel, {});
  }

  public editPartner(partnerDbModel: PartnerDbModel): Observable<{ partner: PartnerDbModel }> {
    const partnerId: number = partnerDbModel.id;
    const partnerDbModelBody: PartnerDbModel = Object.assign({}, partnerDbModel);
    /*partnerDbModelBody.accept_terms_of_services = partnerDbModelBody.accept_terms_of_services ? 1 : 0;
    partnerDbModelBody.subscribe_calenso_newletter = partnerDbModelBody.subscribe_calenso_newletter ? 1 : 0;*/

    return this.httpClientService.put(`partners/${partnerId}`, partnerDbModelBody, {}).pipe(
      (switchMap((result: { partner: PartnerDbModel }) => {
        this.setPartnerInLocalStorage(result.partner);
        return of(result);
      }))
    );
  }

  public editPartnerSpecificValues(partnerDbModel: PartnerDbModel): Observable<{ partner: PartnerDbModel }> {
    const partnerId: number = partnerDbModel.id;
    const partnerDbModelBody: PartnerDbModel = Object.assign({}, partnerDbModel);
    return this.httpClientService.put(`partners/${partnerId}`, partnerDbModelBody, {}).pipe(
      (switchMap((result: { partner: PartnerDbModel }) => {
        this.setPartnerInLocalStorage(result.partner);
        return of(result);
      }))
    );
  }

  public loadPartnerDataAfterLogin(partnerId: number): void {
    this.httpClientService.get(`partners/${partnerId}`, {}).subscribe(
      (result: { partner: PartnerDbModel }) => {
        this.setPartnerInLocalStorage(result.partner);
      },
      (error: Error) => {
        console.error('Error while loading partner data', error);
      }
    );
  }

  public validateConfig(): Observable<ConfigValidationModel> {
    LoggerService.log('validate config called');
    return this.httpClientService.get('partners/validate_config', {}).pipe(
      (switchMap((result: ConfigValidationModel) => {
        // this.setPartnerInLocalStorage(result.partner);
        // this.setSubscriptionDetailInLocalStorage(result.partner.subscription);
        // return of(result);
        this.setConfigValidationInLocalStorage(result);
        return of(result);
      }))
    );
  }

  public initializeDashboard(): Observable<{ partner: PartnerDbModel }> {
    return this.httpClientService.get('workers/initialize_dashboard', {}).pipe(
      (switchMap((result: { partner: PartnerDbModel }) => {
        this.setPartnerInLocalStorage(result.partner);
        this.setSubscriptionDetailInLocalStorage(result.partner.subscription);
        return of(result);
      }))
    );
  }

  public getPartnerById(partnerId: number, refresh?: boolean): Observable<{ partner: PartnerDbModel }> {
    if (!refresh) {
      const partner: PartnerDbModel = this.getPartnerFromLocalStorage();
      if (partner) {
        return of({ partner });
      }
    }

    return this.httpClientService.get(`partners/${partnerId}`, {}).pipe(
      (switchMap((result: { partner: PartnerDbModel }) => {
        this.setPartnerInLocalStorage(result.partner);
        return of(result);
      }))
    );
  }

  public setLangInLocalStorage(partner: PartnerDbModel, loggedInWorker: WorkerDbModel) {
    const localStorageLang: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY);
    const finalLang: string = this.helperService.getDefaultLanguage(localStorageLang, partner, loggedInWorker);
    LoggerService.log('finalLang is ', finalLang);
    this.translate.setDefaultLang(finalLang);
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.CURRENT_LANGUAGE_STATE_KEY, finalLang);
  }

  public uploadLogo(file: File, relativePath: string): Observable<{ partner: PartnerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('logo', file, relativePath);
    return this.httpClientService.post('partners/image', formData, {});
  }

  public uploadLandingPageLogo(file: File, relativePath: string): Observable<{ partner: PartnerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('landingpage_logo', file, relativePath);
    return this.httpClientService.post('partners/image', formData, {});
  }

  public uploadFaviconLogo(file: File, relativePath: string): Observable<{ partner: PartnerDbModel }> {
    LoggerService.log('relative path', relativePath);
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('favicon_logo', file, relativePath);
    return this.httpClientService.post('partners/image', formData, {});
  }

  public uploadPartnerEmailImage(file: File, relativePath: string): Observable<{ partner: PartnerDbModel }> {
    const formData: FormData = new FormData();
    relativePath = relativePath.replace('.', Math.floor(Math.random() * 10000) + '.');
    formData.append('email_logo', file, relativePath);
    return this.httpClientService.post('partners/image', formData, {});
  }

  public setPartnerInLocalStorage(partner: PartnerDbModel): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(partner)
    );
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.PARTNER_KEY, encryptedString);
    LoggerService.log('partner saved');
  }

  public setConfigValidationInLocalStorage(configValidation: ConfigValidationModel): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(configValidation)
    );
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.CONFIG_VALIDATION, encryptedString);
    LoggerService.log('config validation saved');
  }

  public getPartnerFromLocalStorage(): PartnerDbModel {
    const encryptedPartner: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.PARTNER_KEY);
    if (encryptedPartner) {
      const decryptedPartner: string = this.cryptoService.decryptValue(encryptedPartner);
      const partner: PartnerDbModel = Object.assign({}, JSON.parse(decryptedPartner));
      // LoggerService.log('partner.supported_widget_languages ', partner.supported_widget_languages);
      if (!partner.supported_widget_languages) {
        partner.supported_widget_languages = [];
      }
      return partner;
    } else {
      return null;
    }
  }

  public setSubscriptionDetailInLocalStorage(subscription: SubscriptionModel): void {
    const encryptedString = this.cryptoService.encryptValue(
      JSON.stringify(subscription)
    );
    this.localStorageService.set(LOCAL_STORAGE_CONSTANT.SUBSCRIPTION_DETAIL, encryptedString);
    LoggerService.log('partner saved');
  }

  public getSubscriptionDetailFromLocalStorage(): SubscriptionModel {
    const encrypted: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.SUBSCRIPTION_DETAIL);
    if (encrypted) {
      const decrypted: string = this.cryptoService.decryptValue(encrypted);
      const subscription: SubscriptionModel = Object.assign({}, JSON.parse(decrypted));
      return subscription;
    } else {
      return null;
    }
  }

  public getConfigValidationFromLocalStorage(): ConfigValidationModel {
    const encrypted: string = this.localStorageService.get(LOCAL_STORAGE_CONSTANT.CONFIG_VALIDATION);
    if (encrypted) {
      const decrypted: string = this.cryptoService.decryptValue(encrypted);
      const configValidation: ConfigValidationModel = Object.assign({}, JSON.parse(decrypted));
      return configValidation;
    } else {
      return null;
    }
  }

  public getPartnerId(): number {
    let partnerId = null;
    const partnerDb: PartnerDbModel = this.getPartnerFromLocalStorage();
    if (partnerDb) {
      partnerId = partnerDb.id;
    }
    return partnerId;
  }

  public updateTranslationInfoInPartner(partnerTranslationForm: PartnerTranslationForm, partner: PartnerDbModel, currentLang: string) {
    partner.is_multi_language = partnerTranslationForm.is_multi_language ? 1 : 0;
    APP_CONSTANTS.LANGUAGES.forEach(language => {
      if (partner.is_multi_language) {
        if (language.locale === currentLang) {
          partner.micro_partner_description = partnerTranslationForm['formControl_' + language.value].description;
        }
        partner['micro_partner_description_' + language.value] = partnerTranslationForm['formControl_' + language.value].description;
      } else {
        partner.micro_partner_description = partnerTranslationForm.formControl_default.description;
        partner['micro_partner_description_' + language.value] = undefined;
      }
    });
  }

  public fixPartnerData(partnerDbModel: PartnerDbModel): Observable<{ partner: PartnerDbModel }> {
    const partnerId: number = partnerDbModel.id;
    delete partnerDbModel.id;
    const partnerDbModelBody: PartnerDbModel = Object.assign({}, partnerDbModel);
    return this.httpClientService.put(`partners/${partnerId}`, partnerDbModelBody, {}).pipe(
      (switchMap((result: { partner: PartnerDbModel }) => {
        // this.setPartnerInLocalStorage(result.partner);
        return of(result);
      }))
    );
  }

  public addPersonToUnbluConversation(
    unbluPersonId: string,
    appointmentUuid: string,
    partnerUUID: string
  ): Observable<{ success: boolean }> {
    const body: {
      unblu_person_id: string,
      appointment_uuid: string,
    } = {
      unblu_person_id: unbluPersonId,
      appointment_uuid: appointmentUuid,
    };

    const params: any = {
      headers: {
        'X-Requested-For': partnerUUID
      }
    };

    return this.httpClientService.post('partners/add_person_to_unblu_conversation', body, params);
  }

  public requestDigitalizationSuite(payload: DigitizationSuiteFormModel): Observable<any> {
    return this.httpClientService.post('partners/request_digitalization_suite', payload, {});
  }
}
