export const environment = {
  production: false,
  hostName: 'https://sta.my.calenso.com',
  bookingWebcomponentUrl: 'https://staging.webcomponent.calenso.com/booking.html',
  clientDomain: 'https://staging.calenso.com',
  smartWidgetUrl: 'https://staging.smartwidget.calenso.com',
  restAPI: '/api/v1/',
  v2RestAPI: '/api/v2/',
  loginToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJJMndTdzR3N2hseVJxSVRXIiwiaXNzIjoiaHR0cHM6XC9cL2NhbGVuc28iLCJhdWQiOjYsImlhdCI6MTU2NDU4MTIzOSwiZXhwIjoxODc5OTQxMjM5LCJzY29wZSI6MzEsInN1YnNjcmlwdGlvbiI6eyJpZCI6ODk4NSwic3Vic2NyaXB0aW9uVHlwZUlkIjozLCJlbXBsb3llZXMiOjE1LCJjcmVhdGVkIjoiMjAxOS0wMy0xNVQwOTo1MToyNSswMDowMCIsInN0YXJ0IjoiMjAxOS0wMy0xNVQwMDowMDowMCswMDowMCIsImVuZCI6IjIwMjQtMDMtMTVUMDA6MDA6MDArMDA6MDAiLCJzdGF0dXMiOjF9LCJwYXJ0bmVyIjp7ImlkIjo2LCJuYW1lIjoiQnJhaW5jZXB0IEFHIiwiYm9va2luZ19uYW1lIjoiYnJhaW5jZXB0IiwiY3VycmVuY3kiOiJDSEYiLCJsYW5ndWFnZUlkIjoxLCJjb3VudHJ5SWQiOjF9LCJyb2xlIjp7ImlkIjozMSwibmFtZSI6IkxvZ2luIiwiZGVzY3JpcHRpb24iOiJMb2dpbiBvbmx5LiJ9LCJ3b3JrZXIiOm51bGx9.VkRV1vYvSd2vpSWRwtRzFgzz5TQiNLjkM7yPcPTwamY',
  encryptedKey: 'eVJxSVRXIiwiaXNzIjoiaHR0cHM6XC9cL2NhbG',
  imagePath: 'https://sta.my.calenso.com/files/workers/avatar/',
  partnersImagePath: 'https://sta.my.calenso.com/files/partners/logo/',
  landingPageImagePath: 'https://sta.my.calenso.com/files/partners/landingpage_logo/',
  faviconImagePath: 'https://sta.my.calenso.com/files/partners/favicon_logo/',
  emailImagePath: 'https://sta.my.calenso.com/files/partners/email_logo/',
  eventImagePath: 'https://sta.my.calenso.com/webroot/files/events/image/',
  storeImagePath: 'https://sta.my.calenso.com/webroot/files/stores/logo/',
  svgDealsImagePath: 'https://sta.my.calenso.com/files/deals/svg_logo/',
  deployUrl: 'https://staging.calenso.com/',
  bookingSiteUrl: 'https://sta.my.calenso.com/book',
  registerUrl: 'https://calenso.bcpt.ch/registrierung',
  webcomponentWidgetUrl: 'https://staging.widget.calenso.com',
  iframeWidgetUrl: 'https://staging.widget.calenso.com',
  disclaimer_de_CH: `Dieser Service wird von <a href='https://www.calenso.com' class='cal-link' target='_blank'>Calenso</a> bereitgestellt.`,
  disclaimer_en_US: `This service is provided by <a href='https://www.calenso.com' class='cal-link' target='_blank'>Calenso</a>.`,
  disclaimer_fr_CH: `Ce service est fourni par <a href='https://www.calenso.com' class='cal-link' target='_blank'>Calenso</a>.`,
  disclaimer_it_CH: `Questo servizio è fornito da <a href='https://www.calenso.com' class='cal-link' target='_blank'>Calenso</a>.`,
  disclaimer_nl_NL: `Deze dienst wordt u aangeboden door <a href='https://www.calenso.com' class='cal-link' target='_blank'>Calenso</a>.`,
  disclaimer_fi_FI: `Tämän palvelun tarjoaa <a href='https://www.calenso.com' class='cal-link' target='_blank'>Calenso</a>.`,
  stripeConnectUrl: `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=ca_Bq4o9T32MYSRMhpkefr2KwqezmpabCAW&scope=read_write&redirect_uri=https://staging.calenso.com//app/settings/stripe`,
  stripeApiKey: 'pk_test_qC6Q2zbwLgHgHEzXkHdKATbR',
  version: 'v4.41.0',
  googleTagManagerCode: 'GTM-MJC5XGQ',
  stripeCoupon: {
    flatrate: 'prod_Fnr4mF8igaXBsC',
    whitelabel: 'prod_FnrAxSCr9ZOo7z',
},
  office365Conf: {
    url: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?',
    clientId: '&client_id=e2147faf-87f0-4e7f-ad5d-2eb542798506',
    redirectURI: '&redirect_uri=https%3A%2F%2Fsta.my.calenso.com%2Fproxy%2Foffice365authorize',
    setupPageRedirectURI: '&redirect_uri=https%3A%2F%2Fsta.my.calenso.com%2Fproxy%2Foffice365authorizesetup',
    response_type: '&response_type=code',
    state: '&state=',
    scope: '&scope=openid+offline_access+Calendars.ReadWrite',
    plainRedirectUrl: 'https://sta.my.calenso.com/proxy/office365authorize',
    plainSetupPageRedirectURI: 'https://sta.my.calenso.com/proxy/office365authorizesetup',
    teamsScope: '&scope=openid+offline_access+Calendars.ReadWrite+OnlineMeetings.ReadWrite',
    teamsRedirectURI: '&redirect_uri=https%3A%2F%2Fsta.my.calenso.com%2Fproxy%2FmicrosoftTeamsAuthorize',
    plainTeamsRedirectURI: 'https://sta.my.calenso.com/proxy/microsoftTeamsAuthorize'
  },
  googleConf: {
    url: 'https://accounts.google.com/o/oauth2/auth?',
    response_type: 'response_type=code',
    accessType: '&access_type=offline',
    clientId: '&client_id=42888073051-1plfjkkahpkvog19p4pqnt6nti1fvglc.apps.googleusercontent.com',
    redirectURI: '&redirect_uri=https%3A%2F%2Fstaging.calenso.com/app/settings/calendars',
    state: '&state=',
    scope: '&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar',
    approvalPrompt: '&approval_prompt=force',
    setupPageRedirectURI: '&redirect_uri=https%3A%2F%2Fstaging.calenso.com/app/setup/appointment/account?step=4',
  },
  redirect_uri: 'https://staging.calenso.com/app/settings/calendars',
  setupPageRedirectURI: 'https://staging.calenso.com/app/setup/appointment/account?step=4',
  bexioConf: {
    url: 'https://idp.bexio.com/authorize',
    grantType: 'authorization_code',
    clientId: '478ce684-b97d-4dee-9ad8-daa0a1994e56',
    redirectURI: 'https://staging.calenso.com//app/settings/bexio',
    bexio_login_redirect: 'https://staging.calenso.com//connect',
    scope: [
      'openid',
      'profile',
      'offline_access',
      // 'article_show',
      // 'article_edit',
      'contact_show',
      'contact_edit',
      'kb_invoice_show',
      'kb_invoice_edit',
      // 'kb_offer_show',
      // 'kb_offer_edit',
      // 'kb_order_show',
      // 'kb_order_edit',
      // 'lead_show',
      // 'lead_edit',
      // 'monitoring_show',
      // 'monitoring_edit',
      'note_show',
      'note_edit',
      // 'task_show',
      // 'task_edit',
      'bank_account_show',
      // 'bank_payment_show',
      // 'bank_payment_edit',
      'accounting',
      // 'file',
      // 'finance_reports',
      // 'kb_bill_edit',
      // 'kb_bill_show',
      // 'kb_credit_voucher_edit',
      // 'kb_credit_voucher_show',
      // 'kb_delivery_edit',
      // 'kb_delivery_show',
      // 'kb_expense_edit',
      // 'kb_expense_show',
      // 'project_edit',
      // 'project_show',
      // 'stock_edit'
    ],
    bexio_uuid: '0e28c231-f661-4818-a390-9d3ba442b7ec'
  },
  spf_entry_url: 'https://calenso.freshdesk.com/de/support/solutions/articles/80000152422-spf-eintrag-f%C3%BCr-eigenen-e-mail-absender-konfigurieren',
  reseller_contract_url: 'https://www.calenso.com/reseller-contract',
  appointment_token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJ4SGYwXC9pXC9sVjBSYnVMb1QiLCJpc3MiOiJodHRwczpcL1wvY2FsZW5zbyIsImF1ZCI6NiwiaWF0IjoxNTA4MDU2MTE4LCJzY29wZSI6IjI1In0.fMHY9Eb1xvN03lnofWqphyQbKpJ5Gl-_HgTVwGfhGGU',
  zoomConfig: {
    url: 'https://zoom.us/oauth/authorize',
    response_type: 'code',
    client_id: 'W_8WIszFR9eY8BCz92oSEw',
    redirect_uri: 'https://staging.calenso.com/app/settings/meetings'
  },
  gotoMeetingConfig: {
    url: 'https://api.getgo.com/oauth/v2/authorize',
    response_type: 'code',
    client_id: 'e7e84c49-cbc1-4d5b-a31d-6f6613f91240',
    redirect_uri: 'https://staging.calenso.com/app/settings/meetings'
  },
  webexConfig: {
    url: 'https://webexapis.com/v1/authorize',
    response_type: 'code',
    client_id: 'Ceda3fd99741227dc648d5348623a9590db0c73b6246f16a17b2081c64998f6f1',
    redirect_uri: 'https%3A%2F%2Fstaging.calenso.com%2Fapp%2Fsettings%2Fmeetings',
    scope: 'spark%3Akms%20meeting%3Aadmin_schedule_write%20meeting%3Aschedules_read%20meeting%3Aparticipants_read%20meeting%3Aparticipants_write%20meeting%3Aschedules_write'
  },
  tooltipUrls: {
    exceptional_availabilities: {
      de_CH: 'https://calenso.freshdesk.com/de/support/solutions/articles/80000194061-ausserordentliche-verf%C3%BCgbarkeiten',
      en_US: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000194061-exceptional-availabilities',
      fr_CH: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000194061-exceptional-availabilities',
      it_CH: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000194061-exceptional-availabilities',
      nl_NL: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000194061-exceptional-availabilities',
      fi_FI: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000194061-exceptional-availabilities',
    },
    past_invoices: {
      de_CH: 'https://calenso.freshdesk.com/de/support/solutions/articles/80000198045-zahlungsbelege-herunterladen',
      en_US: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000198045-download-payment-receipts',
      fr_CH: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000198045-download-payment-receipts',
      it_CH: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000198045-download-payment-receipts',
      nl_NL: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000198045-download-payment-receipts',
      fi_FI: 'https://calenso.freshdesk.com/en/support/solutions/articles/80000198045-download-payment-receipts',
    },
    sms_settings: {
      de_CH: 'https://calenso.freshdesk.com/a/solutions/articles/80000604204?lang=de',
      en_US: 'https://calenso.freshdesk.com/a/solutions/articles/80000604204?lang=de',
      fr_CH: 'https://calenso.freshdesk.com/a/solutions/articles/80000604204?lang=de',
      it_CH: 'https://calenso.freshdesk.com/a/solutions/articles/80000604204?lang=de',
      nl_NL: 'https://calenso.freshdesk.com/a/solutions/articles/80000604204?lang=de',
      fi_FI: 'https://calenso.freshdesk.com/a/solutions/articles/80000604204?lang=de',
    }
  },
  qrCodeImagePath: 'https://sta.my.calenso.com/files/qrcodes/',
  smartWidget: {
    integrationURL: 'https://staging.smartwidget.calenso.com/assets/smart-widget/main.js'
  },
  preferredCountries: ['ch', 'de', 'fr', 'gb', 'it', 'nl', 'fi']
};
