<div class="d-flex align-items-center justify-content-center vh-100 mx-5">
  <div class="row" *ngIf="paymentSuccess">
    <div class="col-md-12">
      <div class="subtitle">
        <img
          src="/assets/images/check_round.svg"
          height="150"
          class="mb-4"
        />
        <br>
        {{ 'saferPayment.successTitle' | translate }}
      </div>
      <div class="mt-5 main-text">
        {{ 'saferPayment.securityNote' | translate }}
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!paymentSuccess">
    <div class="col-md-12">
      <div class="subtitle">
        <img
          src="/assets/images/warning_ico.svg"
          height="150"
          class="mb-4"
        />
        <br>
        {{ 'saferPayment.failedTitle' | translate }}
      </div>
      <div class="mt-5 main-text">
        {{ 'saferPayment.failedNote2' | translate }}
        <br />
        <br />
        {{ 'saferPayment.failedNote1' | translate }}
      </div>
    </div>
  </div>
</div>
