import {ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {from, Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface ComponentLoader {
  loadChildren: () => Promise<any>;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentLoaderService {

  constructor(private cfr: ComponentFactoryResolver) {
  }

  public loadComponentDynamically(
    vcr: ViewContainerRef,
    loadChildren: () => Promise<any>,
  ): Observable<ComponentRef<any>> {
    vcr.clear();
    return this.forChild(vcr, {
      loadChildren: loadChildren
    });
  }

  private forChild(vcr: ViewContainerRef, cl: ComponentLoader): Observable<ComponentRef<any>> {
    return from(cl.loadChildren()).pipe(
      map((component: any) => this.cfr.resolveComponentFactory(component)),
      map((componentFactory: ComponentFactory<any>) => vcr.createComponent(componentFactory))
    );
  }

/*  public importDealFormComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/deals-dashboard/deals-form/deals-form.component').then(
        m => m.DealsFormComponent
      );
  }

  public importCalioSettingFormComp(): () => Promise<any> {
    return () =>
      import('../../startsite/calio-settings-form/calio-settings-form.component').then(
        m => m.CalioSettingsFormComponent
      );
  }

  public importCustomerNotesFormComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/crm-dashboard/common/customer-notes-form/customer-notes-form.component').then(
        m => m.CustomerNotesFormComponent
      );
  }

  public importQuestionFieldFormComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/dashboard-common/question-fields-form/question-fields-form.component').then(
        m => m.QuestionFieldsFormComponent
      );
  }

  public importCompanySettingTranslationFormComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/dashboard-common/company-setting-translation/company-setting-translation-form/company-setting-translation-form.component').then(
        m => m.CompanySettingTranslationFormComponent
      );
  }

  public importStripeCardDialogComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/settings/common/stripe-card-dialog/stripe-card-dialog.component').then(
        m => m.StripeCardDialogComponent
      );
  }

  public importResellerInviteComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/dashboard-common/reseller-feature/reseller-invite-dialog/reseller-invite-dialog.component').then(
        m => m.ResellerInviteDialogComponent
      );
  }

  public importServiceCategoryComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/dashboard-common/create-service-category/create-service-category.component').then(
        m => m.CreateServiceCategoryComponent
      );
  }

  public importAppointmentServiceComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/setup-account/appointment-services/appointment-services-form/appointment-services-form.component').then(
        m => m.AppointmentServicesFormComponent
      );
  }

  public importAppointmentServiceQuestionComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/setup-account/appointment-services/appointment-services-questions/appointment-services-questions.component').then(
        m => m.AppointmentServicesQuestionsComponent
      );
  }

  public importEmployeeFormComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/setup-account/employee-dashboard/employee-form-dialog/employee-form-dialog.component').then(
        m => m.EmployeeFormDialogComponent
      );
  }

  public importStoreFormComp(): () => Promise<any> {
    return () =>
      import('../../dashboard/dashboard-common/store-common/stores-form/stores-form.component').then(
        m => m.StoresFormComponent
      );
  }*/
}
