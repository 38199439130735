<div class="full-page-loader">
  <div class="loader-container d-flex justify-content-center align-items-center flex-column">

    <mat-spinner [diameter]="15"></mat-spinner>

    <div class="primary-title mt-4 w-50 text-center">
      {{ 'authorize_component.login' | translate }}
      <div>
        <a class="ng-tns-c364-1" href="javascript:void(0)" (click)="logout()">
          {{ 'authorize_component.force_logout' | translate }}
        </a>
      </div>
    </div>
    <div class="secondary-title"></div>
  </div>
</div>
