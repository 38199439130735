import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {CalioDialogComponent} from './calio-dialog.component';
import {CalioDirectivesModule} from "../calio-directives/calio-directives.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatDialogModule} from "@angular/material/dialog";
import {NgSelectModule} from "@ng-select/ng-select";
import {ReactiveFormsModule} from "@angular/forms";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
// import {NgbModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TooltipConfig} from "ngx-bootstrap/tooltip";
import {ColorPickerModule, ColorPickerService} from "ngx-color-picker";
import {CalendarModule, CalendarUtils, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {MatSelectModule} from "@angular/material/select";
import {MatNativeDateModule} from "@angular/material/core";
import {MatDatepickerModule} from "@angular/material/datepicker";

// import {MatTooltipModule} from "@angular/material/tooltip";

@NgModule({
  declarations: [CalioDialogComponent],
  imports: [
    CommonModule,
    CalioDirectivesModule,
    TranslateModule,
    MatDialogModule,
    NgSelectModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    ColorPickerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule
    /*NgbTooltipModule,
    TooltipModule,
    MatTooltipModule*/
    // NgbModule
  ],
  exports: [
    CalioDialogComponent
  ],
  providers: [
    CurrencyPipe,
    TooltipConfig,
    ColorPickerService,
    CalendarUtils,
  ]
})
export class CalioDialogModule {
}
