import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertToastrService {

  constructor(
    private toastr: ToastrService,
    public translate: TranslateService,
  ) {

  }

  showSuccess(messageKey: string, params?: any) {
    this.translate.get(messageKey, params).subscribe((res: string) => {
      this.toastr.success(res, '', {
        tapToDismiss: true,
        enableHtml: true,
        closeButton: true,
        progressBar: true,
        progressAnimation: 'increasing',
        timeOut: 5000,
        easeTime: 300,
        positionClass: 'toast-top-right',
      });
    });
  }

  showError(messageKey: string, params?: any) {
    this.translate.get(messageKey, params).subscribe((res: string) => {
      this.toastr.error(res, '', {
        tapToDismiss: true,
        enableHtml: true,
        closeButton: true,
        progressBar: true,
        progressAnimation: 'increasing',
        timeOut: 5000,
        easeTime: 300,
        positionClass: 'toast-top-right',
      });
    });
  }

  showErrorMessageWithoutTranslation(message: string) {
    this.toastr.error(message, '', {
      tapToDismiss: true,
      enableHtml: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'increasing',
      timeOut: 5000,
      easeTime: 300,
      positionClass: 'toast-top-right',
    });
  }

  showSuccessMessageWithoutTranslation(message: string) {
    this.toastr.success(message, '', {
      tapToDismiss: true,
      enableHtml: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'increasing',
      timeOut: 5000,
      easeTime: 300,
      positionClass: 'toast-top-right',
    });
  }

}
