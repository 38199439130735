import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {LocalStorageService} from '../util-services/local-storage.service';
import {LOCAL_STORAGE_CONSTANT} from '../constants/localstorage.constant';
import {Auth0Service} from '../feature-services/auth-0.service';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private auth0Service: Auth0Service,
    private localStorageService: LocalStorageService,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // LoggerService.log('Inside AuthGuardService canActivate ');
    return this.auth0Service.isAuthenticated$.pipe(
      (switchMap((result: boolean) => {
        // LoggerService.log('Inside AuthGuardService switchMap result ', result);
        if (result) {
          return of(true);
        } else {
          this.auth0Service.login();
          return of(false);
        }
      }))
    );
  }

  loadUserData(jwt: string) {
    this.localStorageService.clear();
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SHORT_APPOINTMENT_SERVICE_LIST_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SHORT_WORKER_LIST_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SCHEDULER_ABSENCES_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.SCHEDULER_AVAILABILITES_KEY);
    this.localStorageService.remove(LOCAL_STORAGE_CONSTANT.WORKERS_KEY);
  }
}
