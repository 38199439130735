import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  hostName: string = environment.hostName;
  restAPI: string = environment.restAPI;
  v2RestAPI: string = environment.v2RestAPI;

  constructor(private httpClient: HttpClient) {

  }

  getJsonFile(path, options): Observable<any> {
    return this.httpClient.get(this.hostName + path, options);
  }

  get(path, options): Observable<any> {
    return this.httpClient.get(this.hostName + this.restAPI + path, options);
  }

  post(path, body, options): Observable<any> {
    return this.httpClient.post(this.hostName + this.restAPI + path, body, options);
  }

  put(path, body, options): Observable<any> {
    return this.httpClient.put(this.hostName + this.restAPI + path, body, options);
  }

  delete(path, options): Observable<any> {
    return this.httpClient.delete(this.hostName + this.restAPI + path, options);
  }

  v2Post(path, body, options): Observable<any> {
    return this.httpClient.post(this.hostName + this.v2RestAPI + path, body, options);
  }
}
