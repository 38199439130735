<div *ngIf="showProdErrorMessage">
  <div class="alert alert-danger" role="alert">
    {{ "general.connected_to_prod_db_error_msg" | translate }}
  </div>
</div>

<div *ngIf="loggedInWorker?.base_account_partner_id != null && loggedInWorker?.base_account_partner_id !== loggedInWorker?.partner_id">
  <div class="alert alert-warning d-flex align-items-end justify-content-center" role="alert">
    {{ "general.reseller_warning_message" | translate: {
      reseller_admin: partner?.name
    } }}
    <button class="btn btn-primary btn-sm reset-reseller-btn ml-2" type="button" (click)="resetResellerAdmin()">
      <span aria-hidden="true">{{ 'header_component.logout' | translate }}</span>
    </button>
  </div>
</div>

<ng-container>
  <ng-progress
    class="progress-bar progress-bar-success"
    id="myProgress"
    #progressBar
    [meteor]="options.meteor" [color]="options.color" [min]="options.min" [speed]="options.speed" [spinner]="options.spinner"
    [max]="options.max" [thick]="options.thick" [trickleSpeed]="options.trickleSpeed" [ease]="options.ease"
  >
  </ng-progress>
  <router-outlet></router-outlet>
</ng-container>
