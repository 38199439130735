import {Component, ComponentRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {DynamicDirectiveDirective} from '../calio-directives/dynamic-directive.directive';
import {DynamicComponentLoaderService} from '../../shared/util-services/dynamic-component-loader.service';
import {Subscription} from 'rxjs';
import {CalioEventsService} from '../../shared/util-services/calio-events.service';
import {LoggerService} from '../../shared/util-services/logger.service';

@Component({
  selector: 'app-calio-dialog',
  templateUrl: './calio-dialog.component.html',
  styleUrls: ['./calio-dialog.component.scss']
})
export class CalioDialogComponent implements OnInit, OnDestroy {

  title: string;
  subtitle: string;
  subtitleTranslationJson: any = {};
  customHeaderClass: string;

  @ViewChild(DynamicDirectiveDirective, {static: true})
  dynamicDirective: DynamicDirectiveDirective;

  loadComponent: () => Promise<any>;
  compData: any;

  loadDynamicComponentSubscription: Subscription;
  closeCalioDialogEventSubscription: Subscription;

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<CalioDialogComponent>,
    private dynamicComponentLoaderService: DynamicComponentLoaderService,
    private calioEventsService: CalioEventsService,
    @Inject(MAT_DIALOG_DATA) public data: {
      loadComponent: () => Promise<any>,
      compData: any,
      dialogTitle: string,
      dialogSubTitle: string,
      showHeader: boolean,
      dialogSubtitleTranslationJson: any,
      customHeaderClass: string
    },
  ) {
    this.title = data.dialogTitle;
    this.subtitle = data.dialogSubTitle;
    this.customHeaderClass = data.customHeaderClass;
    this.loadComponent = data.loadComponent;
    this.compData = data.compData;
    if (data.dialogSubtitleTranslationJson) {
      this.subtitleTranslationJson = data.dialogSubtitleTranslationJson;
    }

    this.closeCalioDialogEventSubscription = this.calioEventsService.closeCalioDialogEvent.subscribe(
      (result: any) => {
        LoggerService.log('Inside this.calioEventsService.closeCalioDialogEvent subscribe result ', result);
        this.dialogRef.close(result);
      }
    );

    this.calioEventsService.calioDialogTitleChangedEvent.subscribe(
      (result: {
        title: string,
        subtitle: string
      }) => {
        this.title = result.title;
        this.subtitle = result.subtitle;
      }
    );
  }

  ngOnInit(): void {
    const viewContainerRef = this.dynamicDirective.viewContainerRef;
    this.loadDynamicComponentSubscription = this.dynamicComponentLoaderService.loadComponentDynamically(
      viewContainerRef,
      this.loadComponent
    ).subscribe({
      next: (result: ComponentRef<any>) => {
        result.instance.compData = this.compData;
      }
    });
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.loadDynamicComponentSubscription) {
      this.loadDynamicComponentSubscription.unsubscribe();
    }
    if (this.closeCalioDialogEventSubscription) {
      this.closeCalioDialogEventSubscription.unsubscribe();
    }
  }
}
